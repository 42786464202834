import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "../icon/icon";

function Header() {
  const [activeHeader, setActiveHeader] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.split("/dashboard")[1] === "") {
      setActiveHeader("Dashboard");
    } else if (window.location.pathname.split("/")[1] === "employee") {
      setActiveHeader("Employee");
    } else if (window.location.pathname.split("/")[1] === "employee-detail") {
      setActiveHeader("Employee Enrollment Request");
    } else if (window.location.pathname.split("/")[1] === "employee-profile") {
      setActiveHeader("Employee Profile");
    } else if (window.location.pathname.split("/")[1] === "patient-detail") {
      setActiveHeader("Patient Detail");
    } else if (
      window.location.pathname.split("/")[1] === "edit-employee-profile"
    ) {
      setActiveHeader("Edit Employee Profile");
    } else if (
      window.location.pathname.split("/")[1] === "new-employee-profile"
    ) {
      setActiveHeader("Add New Employee");
    } else if (window.location.pathname.split("/")[1] === "booking-request") {
      setActiveHeader("Booking Requests");
    } else if (window.location.pathname.split("/")[1] === "booking-detail") {
      setActiveHeader("Booking Request Detail");
    } else if (window.location.pathname.split("/")[1] === "edit-request") {
      setActiveHeader("Edit Booking Request");
    } else if (window.location.pathname.split("/")[1] === "new-request") {
      setActiveHeader("New Booking Request");
    } else if (window.location.pathname.split("/")[1] === "patient") {
      setActiveHeader("All Patients / Customers");
    } else if (
      window.location.pathname.split("/")[1] === "employee-change-password"
    ) {
      setActiveHeader("Employee Change Password");
    } else if (window.location.pathname.split("/")[1] === "employee-assigned") {
      setActiveHeader("Employee Assigned");
    } else if (window.location.pathname.split("/")[1] === "user-profile") {
      setActiveHeader("User Profile");
    } else if (window.location.pathname.split("/")[1] === "edit-user-profile") {
      setActiveHeader("Edit User Profile");
    } else if (
      window.location.pathname.split("/")[1] === "user-change-password"
    ) {
      setActiveHeader("Change Password");
    }
  }, []);

  return (
    <>
      <div className="flex w-full gap-5 items-center">
        {activeHeader !== "Dashboard" ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon />
          </div>
        ) : (
          <></>
        )}
        <h1 className="font-bold text-4xl"> {activeHeader} </h1>
      </div>
    </>
  );
}

export default Header;
