import React, { useState } from "react";
import { SelectIcon } from "../icon/icon";

function Select({ title, value, options, optionType, onClick }) {
  const [active, setActive] = useState(false);

  return (
    <>
      <div
        className="relative"
        onMouseLeave={() => {
          setActive(false);
        }}
      >
        <div
          className="relative cursor-pointer"
          onClick={() => {
            setActive(!active);
          }}
        >
          <p className="text-lg text-avi-color1 font-normal absolute -top-4 left-7 bg-white px-2">
            {title}
          </p>
          <div className="flex justify-between border border-avi-color2 rounded-3xl px-6 py-5 w-full items-center">
            <h5 className="text-xl font-normal text-black min-h-[28px]">
              {value}
            </h5>
            <SelectIcon />
          </div>
        </div>
        {active ? (
          <div className="absolute z-20 w-full">
            <div className="border border-avi-color2 rounded-3xl w-full bg-white ">
              {options.length > 0 ? (
                options.map((item, index) => (
                  <p
                    className="px-6 py-4 cursor-pointer hover:bg-avi-color6 first:rounded-t-3xl last:rounded-b-3xl text-xl font-normal text-black"
                    key={index}
                    onClick={() => {
                      onClick(index);
                      setActive(false);
                    }}
                  >
                    {item[optionType]}
                  </p>
                ))
              ) : (
                <>
                  <p className="px-6 py-4 cursor-pointer hover:bg-avi-color6 first:rounded-t-3xl last:rounded-b-3xl text-xl font-normal text-black">
                    No Data Available{" "}
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Select;
