import React, { useEffect, useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  availableEmployeeApi,
  bookingDetailApi,
  deleteBookingRequestApi,
  employeeAssignApi,
  employeeBookingDetailApi,
  reEmployeeAssignApi,
} from "../../apis/bookingApi/bookingApi";
import moment from "moment";
import { useSelector } from "react-redux";
import DeleteModal from "../../component/modal/deleteModal";

function BookingDetail() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState({});

  async function bookingDetailGettingApi() {
    const result = await bookingDetailApi(id);

    if (result !== false) {
      if (result.length > 0) {
        setData(result[0]);
        if (result[0].status !== "PENDING") {
          setEmployee(result[0].caretaker);
        }
      } else {
        window.location.href = "/dashboard";
      }
    }
  }

  const [employeeList, setEmployeeList] = useState([]);

  const [employee, setEmployee] = useState({});

  const type = useSelector((state) => state.userType);

  const [modalState, setModalState] = useState(false);

  async function availableEmployeeListApi() {
    const result = await availableEmployeeApi(id);

    if (result !== false) {
      setEmployeeList(result);
    }
  }

  async function bookingRemoveApi() {
    const result = await deleteBookingRequestApi(id);

    if (result !== false) {
      navigate("/booking-request");
    }
  }

  async function assignEmployeeApi() {
    const result = await employeeAssignApi(id, { empId: employee.id });

    if (result !== false) {
      navigate("/booking-request");
    }
  }

  async function reAssignEmployeeApi() {
    const result = await reEmployeeAssignApi(id, { empId: employee.id });
    if (result !== false) {
      navigate("/booking-request");
    }
  }

  async function bookingEmployeeDetailGettingApi() {
    const result = await employeeBookingDetailApi(id);

    if (result !== false) {
      setData(result[0]);
    }
  }

  useEffect(() => {
    if (type === "EMPLOYEE") {
      bookingEmployeeDetailGettingApi();
    } else {
      bookingDetailGettingApi();
      availableEmployeeListApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">{data.fullName}</h4>
              <p className="text-black text-lg font-normal opacity-50">
                Patient Name
              </p>
            </div>
            {type !== "EMPLOYEE" && data.status !== "COMPLETED" ? (
              <div className="flex gap-5">
                <Button
                  design="secondary"
                  content="Remove Request"
                  padding="py-4 px-10"
                  onClick={() => {
                    // bookingRemoveApi();
                    setModalState(true);
                  }}
                />
                <Link to={"/edit-request/" + data.id}>
                  <Button
                    design="ternary"
                    content="Edit Request"
                    padding="py-4 px-10"
                  />
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="py-6 px-7 grid grid-cols-4 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data && data.designation && data.designation[0]
                  ? data.designation[0] +
                    data.designation.substring(1).toLowerCase()
                  : ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Requirement
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data.medicalCondition}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Medical Condition
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">{data.age}</h5>
              <p className="font-normal text-black text-lg opacity-50">Age</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data.locationName}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Location
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data && data.gender && data.gender[0]
                  ? data.gender[0] + data.gender.substring(1).toLowerCase()
                  : ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Gender
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">{data.contact}</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Contact
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">{data.weight}</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Weight in Kg
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {moment(Number(data.requestedDate)).format("LLL")}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Requested Date
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Location Information
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data.line1} , {data.locationName} , {data.landmark} ,{" "}
                {data.city}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Address
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">{data.state}</h5>
              <p className="font-normal text-black text-lg opacity-50">State</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">{data.zipcode}</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Postal Code
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">Extra Information</h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-1 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data.otherDetails}
              </h5>
            </div>
          </div>
          {type !== "EMPLOYEE" && data.status !== "COMPLETED" ? (
            <div className="flex justify-between py-6 px-7  rounded-b-3xl">
              <div className="min-w-[400px]">
                <Select
                  title={
                    data.status === "PENDING"
                      ? "Assign an Employee"
                      : "Reassign an Employee"
                  }
                  value={employee.name}
                  options={employeeList}
                  optionType="name"
                  onClick={(index) => {
                    setEmployee(employeeList[index]);
                  }}
                />
              </div>
              <div>
                <Button
                  design="quaternary"
                  content={
                    data.status === "PENDING"
                      ? "Assign Employee"
                      : "Assign Employee"
                  }
                  padding="py-4 px-10"
                  onClick={() => {
                    data.status === "PENDING"
                      ? assignEmployeeApi()
                      : reAssignEmployeeApi();
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Layout>
      <DeleteModal
        modalState={modalState}
        setModalState={setModalState}
        onDelete={() => bookingRemoveApi()}
      />
    </>
  );
}

export default BookingDetail;
