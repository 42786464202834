import React from "react";
import Layout from "../../component/layout/layout";
import EmployeeTable from "../../component/table/employeeTable";

function Patient() {
  const columns = [
    {
      Header: "Project Name",
      accessor: "employeeName",
    },
    {
      Header: "Requirements",
      accessor: "designation",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Action",
    },
  ];

  const data = [
    {
      employeeName: "Rajesh",
      designation: "Caregiver (Specialist)",
      gender: "Male",
      age: "38",
      location: "Mehrauli",
      address:
        "43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind Lakshminagar post office, Assam",
    },
    {
      employeeName: "Meenu Singh",
      designation: "Cardiologists",
      gender: "Male",
      age: "28",
      location: "Saket",
      address:
        "43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind Lakshminagar post office, Bihar",
    },
    {
      employeeName: "Bhaga Guha",
      designation: "Cardiologists",
      gender: "Male",
      age: "24",
      location: "Assam",
      address:
        "43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind Lakshminagar post office, Bihar",
    },
    {
      employeeName: "Mali Guha",
      designation: "Pediatricians",
      gender: "Female",
      age: "56",
      location: "Gujarat",
      address:
        "43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind Lakshminagar post office, Gujarat",
    },
  ];

  return (
    <>
      <Layout>
        <div className="">
          <EmployeeTable columns={columns} data={data} loading={false} button={false} />
        </div>
      </Layout>
    </>
  );
}

export default Patient;
