import React from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";

function patientDetail() {
  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Kanyakumari Devi
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Patient Name
              </p>
            </div>
            <div className="flex">
              <div className="mr-4">
                <Button
                  design="secondary"
                  content="Remove Profile"
                  padding="py-4 px-10"
                />
              </div>
              <div>
                <Button
                  design="ternary"
                  content="Edit Profile"
                  padding="py-4 px-10"
                />
              </div>
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-4 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Physiotherapist
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Requirement
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Parkinsons, Alzheimers
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Ailments
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">87</h5>
              <p className="font-normal text-black text-lg opacity-50">Age</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Maulana Azad Colony
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Location
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">Female</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Gender
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                +91 918 892 3842
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Emergency Contact
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">58</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Weight in Kg
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Location Information
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind
                Lakshminagar post office,
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Address
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">New Delhi</h5>
              <p className="font-normal text-black text-lg opacity-50">State</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">110021</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Postal Code
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">Extra Information</h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-1 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velitesse molestie consequat, vel
                illum dolore eu feugiat nulla facilisis at vero eros et
              </h5>
            </div>
          </div>
        </div>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">Past Request</h4>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-5 px-7 py-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Rohit Kapoor Mehta
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Caregiver Name
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">30 August 2022</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Date of Assignment
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Lorem ipsum dolor...
                <span className="text-avi-color1 cursor-pointer">
                  {" "}
                  Read More
                </span>
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Review
              </p>
            </div>
            <div className="justify-self-end">
              <Button
                design="primary"
                content="Caregiver Details"
                padding="py-4 px-10"
              />
            </div>
          </div>
          <div>
            <div className="grid grid-cols-4 gap-5 px-7 py-5">
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Rohit Kapoor Mehta
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Caregiver Name
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  30 August 2022
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Date of Assignment
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Lorem ipsum dolor...
                  <span className="text-avi-color1 cursor-pointer">
                    {" "}
                    Read More
                  </span>
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Review
                </p>
              </div>
              <div className="justify-self-end">
                <Button
                  design="primary"
                  content="Caregiver Details"
                  padding="py-4 px-10"
                />
              </div>
            </div>
            <div className="px-7 py-5">
              <div className="w-full grid grid-cols-1 bg-avi-color5 px-5 py-3 rounded-3xl border border-avi-color1">
                <h5 className="font-normal text-black text-xl">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibheuismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim adminim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip exea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velitesse molestie consequat,
                  vel illum dolore eu feugiat nulla facilisis at vero eros et
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default patientDetail;
