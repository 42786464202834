import React, { useEffect, useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
import Input from "../../component/input/input";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeUserAcceptanceApi,
  getEmployeeDetailApi,
} from "../../apis/employeeApi/employeeApi";
import { changePasswordApi } from "../../apis/authApi/authApi";
import { successToast } from "../../utils/utils";

function EmployeeChangePassword() {
  const statusList = [
    { name: "Enable", value: "enable" },
    { name: "Disable", value: "disable" },
  ];

  const [status, setStatus] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  async function employeeDetail() {
    const result = await getEmployeeDetailApi(id);

    if (result !== false) {
      setStatus(result[0].isAccepted ? "Enable" : "Disable");
    }
  }

  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  async function changePassword(e) {
    e.preventDefault();
    if (password === confirmPassword) {
      const result = await changePasswordApi(id, { password: password });
      if (result !== false) {
        successToast("SuccessFully Edited Employee Password");
        setTimeout(() => {
          navigate("/employee");
        }, 1000);
      }
    }
  }

  async function changeStatus(e) {
    e.preventDefault();
    const result = await changeUserAcceptanceApi(id, {
      isAccepted: status === "Enable" ? true : false,
    });

    if (result !== false) {
      successToast("SuccessFully Edited Employee Status");
      setTimeout(() => {
        navigate("/employee");
      }, 1000);
    }
  }

  useEffect(() => {
    employeeDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <form
          className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl"
          onSubmit={changePassword}
        >
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Change Password for Employee
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee change password
              </p>
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-1 gap-7">
            <Input
              title="Change Password *"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
            <Input
              title="Confirm Change Password *"
              type="password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              required
            />
          </div>
          <div className="py-6 px-7 flex gap-5">
            <Button
              design="primary"
              content="Save Password"
              padding="py-4 px-10"
              type="submit"
            />
          </div>
        </form>
        <form
          className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-7"
          onSubmit={changeStatus}
        >
          <div className="py-6 px-7 bg-avi-color6 rounded-t-3xl">
            <h4 className="font-bold text-2xl text-black">
              Enable/Disable User Status
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <Select
              title="Status *"
              value={status}
              options={statusList}
              optionType="name"
              onClick={(index) => {
                setStatus(statusList[index].name);
              }}
            />
          </div>
          <div className="py-6 px-7 flex gap-5">
            <Button
              design="primary"
              content="Save Changes"
              padding="py-4 px-10"
              type="submit"
            />
            <Button
              design="secondary"
              content="Discard Changes"
              padding="py-4 px-10"
              type="button"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </form>
      </Layout>
    </>
  );
}

export default EmployeeChangePassword;
