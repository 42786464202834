import axios from "axios";
import { getAuthHeader } from "../../redux/services/authHeader";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** addding employee api function start */

export const employeeApi = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "admin/employee", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** addding employee api function end */

/** getting employee api function start */

export const getEmployeeApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "admin/employees?type=EMPLOYEE&isAccepted=true",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** getting employee api function end */

/** getting employee api function start */

export const getDisableEmployeeApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "admin/employees?type=EMPLOYEE&isAccepted=false",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** getting employee api function end */

/** getting employee detail api function start */

export const getEmployeeDetailApi = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/employees?id=" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** getting employee detail api function end */

/** edited employee detail api function start */

export const editEmployeeDetailApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .put(process.env.REACT_APP_API_URL + "admin/employee/" + id, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** edited employee detail api function end */

/** delete employee detail api function start */

export const deleteEmployeeDetailApi = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .delete(process.env.REACT_APP_API_URL + "admin/employee/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** delete employee detail api function end */

/** change User Acceptance detail api function start */

export const changeUserAcceptanceApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "admin/status/" + id, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** change User Acceptance detail api function end */

/** change User Status detail api function start */

export const changeUserStatusApi = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .put(process.env.REACT_APP_API_URL + "admin/status", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** change User Status detail api function end */

/** register employee api function start */

export const registerEmployeeApi = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "employee", data)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};
/** register employee api function end */

/** upload employee file function start */
export const uploadFileApi = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const result = await axios({
    url: process.env.REACT_APP_API_URL + "upload",
    method: "POST",
    data: formData,
  });

  return result;
};

/** upload employee file function end */
