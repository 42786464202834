import React, { useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
import Input from "../../component/input/input";
import { useNavigate, useParams } from "react-router-dom";
import {
  bookingDetailApi,
  editBookingRequestApi,
} from "../../apis/bookingApi/bookingApi";
import { useEffect } from "react";
import {
  designationEnumsApi,
  genderEnumsApi,
} from "../../apis/enumsApi/enumsApi";
import moment from "moment";
import { successToast } from "../../utils/utils";

function EditRequest() {
  const [genderList, setGenderList] = useState([]);

  const [gender, setGender] = useState({});

  const [destinationList, setDestinationList] = useState([]);

  const [destination, setDestination] = useState({});

  const [data, setData] = useState({
    name: "",
    age: "",
    weight: "",
    contact: "",
    address: "",
    locality: "",
    landmark: "",
    city: "",
    state: "",
    postal: "",
    information: "",
    requestedDate: "",
    medicalCondition: "",
  });

  const { id } = useParams();

  const navigate = useNavigate();

  async function editRequestApi(e) {
    e.preventDefault();
    const result = await editBookingRequestApi(id, {
      designation: destination.value,
      fullName: data.name,
      gender: gender.value,
      age: data.age,
      weight: data.weight,
      locationName: data.locality,
      line1: data.address,
      city: data.city,
      landmark: data.landmark,
      state: data.state,
      zipcode: data.postal,
      contact: data.contact,
      medicalCondition: data.medicalCondition,
      otherDetails: data.information,
    });

    if (result !== false) {
      setTimeout(() => {
        navigate("/booking-detail/" + id);
      }, 1000);

      successToast("SuccessFully Edited Booking Request");
    }
  }

  async function getDesignation() {
    const result = await designationEnumsApi();

    const destinationArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      destinationArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setDestinationList(destinationArray);
  }

  async function getGender() {
    const result = await genderEnumsApi();

    const genderArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      genderArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setGenderList(genderArray);
  }
  async function bookingDetailGettingApi() {
    const result = await bookingDetailApi(id);

    if (result !== false) {
      setData({
        name: result[0].fullName,
        age: result[0].age,
        weight: result[0].weight,
        contact: result[0].contact,
        address: result[0].line1,
        locality: result[0].locationName,
        landmark: result[0].landmark,
        city: result[0].city,
        state: result[0].state,
        postal: result[0].zipcode,
        information: result[0].otherDetails,
        requestedDate: result[0].requestedDate,
        medicalCondition: result[0].medicalCondition,
      });

      if (result && result[0] && result[0].gender) {
        setGender({
          name:
            result[0].gender[0] + result[0].gender.substring(1).toLowerCase(),
          value: result[0].gender || "",
        });
      }

      if (result && result[0] && result[0].designation) {
        setDestination({
          name:
            result[0].designation[0] +
            result[0].designation.substring(1).toLowerCase(),
          value: result[0].designation || "",
        });
      }
    }
  }

  useEffect(() => {
    bookingDetailGettingApi();
    getDesignation();
    getGender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Edit Booking Request
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Booking Request Details
              </p>
            </div>
            <div className="text-right">
              <h4 className="font-bold text-2xl text-black">
                {moment(Number(data.requestedDate)).format("LLL")}
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Enrollment Request Date
              </p>
            </div>
          </div>
          <form
            onSubmit={editRequestApi}
            className="divide-y divide-avi-color5"
          >
            <div className="py-6 px-7 grid grid-cols-4 gap-7">
              <Input
                title="Patient Name *"
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData((data) => ({ ...data, name: e.target.value }));
                }}
                required
              />
              <Select
                title="Requirement *"
                value={destination.name}
                options={destinationList}
                optionType="name"
                onClick={(index) => {
                  setDestination(destinationList[index]);
                }}
              />
              <Input
                title="Age *"
                type="number"
                value={data.age}
                onChange={(e) => {
                  setData((data) => ({ ...data, age: e.target.value }));
                }}
                required
              />
              <Input
                title="Weight *"
                type="number"
                value={data.weight}
                onChange={(e) => {
                  setData((data) => ({ ...data, weight: e.target.value }));
                }}
                required
              />
              <Select
                title="Gender *"
                value={gender.name}
                options={genderList}
                optionType="name"
                onClick={(index) => {
                  setGender(genderList[index]);
                }}
              />
              <Input
                title="Contact *"
                type="number"
                min={1000000000}
                max={9999999999}
                value={data.contact}
                onChange={(e) => {
                  setData((data) => ({ ...data, contact: e.target.value }));
                }}
                required
              />
              <Input
                title="Medical Condition *"
                type="text"
                value={data.medicalCondition}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    medicalCondition: e.target.value,
                  }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Location Details
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-3 gap-5">
              <Input
                title="Address Line 1 *"
                type="text"
                value={data.address}
                onChange={(e) => {
                  setData((data) => ({ ...data, address: e.target.value }));
                }}
                required
              />
              <Input
                title="Locality Name"
                type="text"
                value={data.locality}
                onChange={(e) => {
                  setData((data) => ({ ...data, locality: e.target.value }));
                }}
                required
              />
              <Input
                title="Landmark Name *"
                type="text"
                value={data.landmark}
                onChange={(e) => {
                  setData((data) => ({ ...data, landmark: e.target.value }));
                }}
                required
              />
              <Input
                title="City Name *"
                type="text"
                value={data.city}
                onChange={(e) => {
                  setData((data) => ({ ...data, city: e.target.value }));
                }}
                required
              />
              <Input
                title="State *"
                type="text"
                value={data.state}
                onChange={(e) => {
                  setData((data) => ({ ...data, state: e.target.value }));
                }}
                required
              />
              <Input
                title="Postal code *"
                type="number"
                value={data.postal}
                onChange={(e) => {
                  setData((data) => ({ ...data, postal: e.target.value }));
                }}
                required
              />
            </div>
            <div className="flex justify-between py-6 px-7 bg-avi-color6 items-center">
              <h4 className="font-bold text-2xl text-black">
                Extra Information
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-1 gap-5">
              <Input
                title="Information *"
                type="text"
                value={data.information}
                onChange={(e) => {
                  setData((data) => ({ ...data, information: e.target.value }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Save Changes"
                padding="py-4 px-10"
                type="submit"
              />
              <Button
                design="secondary"
                content="Discard Changes"
                padding="py-4 px-10"
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}

export default EditRequest;
