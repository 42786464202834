import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { noAuthBookingGetApi } from "../../apis/noAuthApi/noAuthApi";
import nodata from "../../assets/images/No data-amico.svg";
import moment from "moment";
import { LoadingIcon, Logo } from "../../component/icon/icon";

function ViewBookingRequest() {
  const [loading, setLoading] = useState(false);

  const [noData, setNoData] = useState(true);

  const { id } = useParams();

  const [data, setData] = useState({});

  async function bookingDetailGettingApi() {
    const result = await noAuthBookingGetApi(id);

    if (result !== false) {
      setData(result);
      setLoading(true);
    } else {
      setLoading(true);
      setNoData(false);
    }
  }

  useEffect(() => {
    bookingDetailGettingApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 lg:px-20 py-16 lg:mb-0 mb-28">
      <div className="mb-6 flex justify-center gap-6 items-center">
        <div className=" cursor-pointer">
          <Logo />
        </div>
        <h2 className="text-3xl text-avi-color3 font-bold cursor-pointer">
          {" "}
          AVI Caretaker{" "}
        </h2>
      </div>
      {loading ? (
        noData ? (
          <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
            <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
              <div className="">
                <h4 className="font-bold text-2xl text-black">
                  {data.fullName}
                </h4>
                <p className="text-black text-lg font-normal opacity-50">
                  Patient Name
                </p>
              </div>
              <div className="text-right">
                <h4 className="font-bold text-2xl text-black">{data.status}</h4>
                <p className="text-black text-lg font-normal opacity-50">
                  Booking Status
                </p>
              </div>
            </div>
            <div className="py-6 px-7 grid lg:grid-cols-4 gap-5">
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data && data.designation && data.designation[0]
                    ? data.designation[0] +
                      data.designation.substring(1).toLowerCase()
                    : ""}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Requirement
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.medicalCondition}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Medical Condition
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">{data.age}</h5>
                <p className="font-normal text-black text-lg opacity-50">Age</p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.locationName}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Location
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data && data.gender && data.gender[0]
                    ? data.gender[0] + data.gender.substring(1).toLowerCase()
                    : ""}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Gender
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.contact}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Contact
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.weight}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Weight in Kg
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {moment(Number(data.requestedDate)).format("LLL")}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Requested Date
                </p>
              </div>
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Location Information
              </h4>
            </div>
            <div className="py-6 px-7 grid lg:grid-cols-3 gap-5">
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.line1} , {data.locationName} , {data.landmark} ,{" "}
                  {data.city}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Address
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">{data.state}</h5>
                <p className="font-normal text-black text-lg opacity-50">
                  State
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.zipcode}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Postal Code
                </p>
              </div>
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Extra Information
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-1 gap-5">
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data.otherDetails}
                </h5>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="">
              <img src={nodata} alt="" className="h-[500px] m-auto" />
            </div>
          </>
        )
      ) : (
        <>
          <LoadingIcon />
        </>
      )}
    </div>
  );
}

export default ViewBookingRequest;
