import axios from "axios";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** no auth booking request api function start */

export const noAuthBookingApi = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "request", data)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** no auth booking request api function end */

/** no auth booking request get api function start */

export const noAuthBookingGetApi = async (id) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "request/" + id)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** no auth booking request api function end */

/** no auth employee request get api function start */

export const noAuthEmployeeGetApi = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "employee", data)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** no auth employee request api function end */
