import React, { useState } from "react";
import Button from "../../component/button/button";
import Input from "../../component/input/input";
import { patientFeedbackApi } from "../../apis/patientApi/patientApi";
import { Logo } from "../../component/icon/icon";

function PatientFeedback() {
  const [id, setId] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);

  async function patientFeedbackFunctionApi(e) {
    e.preventDefault();
    const result = await patientFeedbackApi({
      id: id,
      feedback: feedback,
    });

    if (result !== false) {
      setSubmitted(true);
      setTimeout(() => {
        window.location.href = "/view-booking-request/" + id;
      }, 2000);
    }
  }

  return (
    <>
      <div className="px-4 lg:px-20 py-16 lg:mb-0 mb-28">
        <div className="mb-6 flex justify-center gap-6 items-center">
          <div className=" cursor-pointer">
            <Logo />
          </div>
          <h2 className="text-3xl text-avi-color3 font-bold cursor-pointer">
            {" "}
            AVI Caretaker{" "}
          </h2>
        </div>
        {submitted ? (
          <div className="flex items-center h-screen justify-center pb-28">
            <h1 className="font-bold text-3xl text-black">
              Thanks for submitting your feedback!
            </h1>
          </div>
        ) : (
          <>
            <form
              onSubmit={patientFeedbackFunctionApi}
              className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl"
            >
              <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
                <div className="">
                  <h4 className="font-bold text-2xl text-black">
                    Patient Feedback
                  </h4>
                  <p className="text-black text-lg font-normal opacity-50">
                    Patient Feedback Details
                  </p>
                </div>
              </div>
              <div className="py-6 px-7 grid grid-cols-1 gap-7">
                <div className="relative">
                  <Input
                    title="Enter Booking No. *"
                    type="number"
                    required
                    onChange={(e) => {
                      setId(e.target.value);
                    }}
                  />
                </div>
                <div className="relative">
                  <Input
                    title="Patient Feedback *"
                    type="text"
                    required
                    onChange={(e) => {
                      setFeedback(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="py-6 px-7 flex gap-5">
                <Button
                  design="primary"
                  content="Submit"
                  padding="py-4 px-10"
                />
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default PatientFeedback;
