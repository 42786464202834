import React from "react";
import nodata from "../../assets/images/No data-amico.svg";

function PageNotFound() {
  return (
    <div className="min-h-screen w-full flex flex-col justify-center">
      <div className="border border-avi-color2 flex flex-col divide-avi-color5 rounded-3xl min-w-[800px] m-auto">
        <div className="">
          <img src={nodata} alt="" className="h-[500px] m-auto" />
        </div>
        <h1 className="text-center font-bold text-4xl pb-10">
          404 Page Not Found
        </h1>
      </div>
    </div>
  );
}

export default PageNotFound;
