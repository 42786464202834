import React from "react";

function Input(props) {
  return (
    <>
      <div className="relative">
        <p className="text-lg text-avi-color1 font-normal absolute -top-4 left-7 bg-white px-2 ">
          {props.title}
        </p>
        <input
          className="border border-avi-color2 rounded-3xl px-6 py-5 focus:outline-none text-xl font-normal text-black w-full"
          {...props}
        />
      </div>
    </>
  );
}

export default Input;
