import { combineReducers } from "redux";
import { accessToken } from "./accessToken/reducer.accessToken";
import { refreshToken } from "./refreshToken/reducer.refreshToken";
import { userType } from "./userType/reducer.userType";

const rootReducer = combineReducers({
  accessToken: accessToken,
  refreshToken: refreshToken,
  userType: userType,
});

export default rootReducer;
