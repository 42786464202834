import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useFilters, // useFilters!
  useGlobalFilter,
} from "react-table";
import React from "react";
import { Link } from "react-router-dom";
import { matchSorter } from "match-sorter";
import "./table.css";
import {
  DownloadIcon,
  FilterIcon,
  LoadingIcon,
  SearchIcon,
} from "../icon/icon";
import nodata from "../../assets/images/No data-amico.svg";
import Button from "../../component/button/button";
import { CSVLink } from "react-csv";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  //const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex border-2 border-x-avi-color4 p-4 bg-white rounded-3xl">
      <input
        type="text"
        className="focus:outline-none text-lg font-medium text-black placeholder:opacity-50"
        placeholder="Search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <div className="cursor-pointer">
        <SearchIcon />
      </div>
    </div>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function EmployeeTable({ columns, data, loading, button }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    usePagination
  );

  const csvHeader = [
    {
      label: "Employee Name",
      key: "name",
    },
    {
      label: "Designation",
      key: "profile.designation",
    },
    {
      label: "Gender",
      key: "profile.gender",
    },
    {
      label: "Age",
      key: "profile.age",
    },
    {
      label: "Location",
      key: "profile.location.locationName",
    },
    // {
    //   label: "Busy",
    //   key: "isActive?",
    // },
  ];

  // Render the UI for your table
  return (
    <>
      <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-10">
        <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
          <div className="">
            <h4 className="font-bold text-2xl text-black">
              All Authorized Employees
            </h4>
            <p className="text-black text-lg font-normal opacity-50">
              All Accepted Employee
            </p>
          </div>
          <div className="flex gap-5 items-center">
            <div className="rounded-3xl border-2 border-x-avi-color4 p-4 bg-white cursor-pointer hidden">
              <FilterIcon />
            </div>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <CSVLink
              className="rounded-3xl border-2 border-x-avi-color4 p-4 bg-white cursor-pointer"
              filename="Accepted Employee data.csv"
              target="_blank"
              data={data}
              headers={csvHeader}
            >
              <DownloadIcon />
            </CSVLink>
            {button ? (
              <Link to="/new-employee-profile">
                <Button
                  design="ternary"
                  content="Add New Employee"
                  padding="py-4 px-10"
                />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        {loading ? (
          <>
            <div className="p-10">
              <LoadingIcon />
            </div>
          </>
        ) : data.length > 0 ? (
          <>
            <table
              {...getTableProps()}
              className="table-auto w-full divide-y divide-avi-color5 "
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="text-left"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className={
                          column.Header === "Action"
                            ? "text-right text-sm font-semibold text-black px-7 py-3"
                            : "text-sm font-semibold text-black px-7 py-3"
                        }
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-avi-color5 "
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="group hover:bg-avi-color6 cursor-pointer"
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} className="">
                          {cell.column.Header === "Work Status" ? (
                            cell.value ? (
                              <div className="flex items-center gap-2  px-7 py-3">
                                <div className="rounded-full h-3 w-3 border-2 border-avi-color8 bg-avi-color7"></div>
                                <h5 className="text-black opacity-50 group-hover:opacity-100 text-lg font-medium ">
                                  Busy
                                </h5>
                              </div>
                            ) : (
                              <div className="flex items-center gap-2  px-7 py-3">
                                <div className="rounded-full h-3 w-3 border-2 border-avi-color10 bg-avi-color9"></div>
                                <h5 className="text-black opacity-50 group-hover:opacity-100 text-lg font-medium ">
                                  Free
                                </h5>
                              </div>
                            )
                          ) : cell.column.Header === "Employee Status" ? (
                            cell.value ? (
                              <h5 className="text-black opacity-50 group-hover:opacity-100 text-lg px-7 py-3 font-medium ">
                                User Enabled
                              </h5>
                            ) : (
                              <h5 className="text-black opacity-50 group-hover:opacity-100 text-lg px-7 py-3 font-medium ">
                                User Disabled
                              </h5>
                            )
                          ) : cell.column.Header === "Action" ? (
                            <h5
                              className="text-black opacity-50 group-hover:opacity-100 text-lg px-7 py-3 font-medium text-right"
                              onClick={() => {
                                window.location.href =
                                  "/employee-profile/" +
                                    cell?.row?.original?.id || "";
                              }}
                            >
                              View &#10132;
                            </h5>
                          ) : (
                            <h5 className="text-black opacity-50 group-hover:opacity-100 text-lg px-7 py-3 font-medium ">
                              {cell.value}
                            </h5>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
        */}
            <div className="flex gap-5 justify-end px-7 py-6">
              <div
                className="cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-3.5 py-2 border-2 border-avi-color4"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </div>
              {pageOptions.length > 4 ? (
                pageIndex === 0 || pageIndex === 1 ? (
                  <div className="flex gap-2">
                    <h6
                      className={
                        pageIndex === 0
                          ? "cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                          : "cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      }
                      onClick={() => gotoPage(0)}
                    >
                      1
                    </h6>
                    <h6
                      className={
                        pageIndex === 1
                          ? "cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                          : "cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      }
                      onClick={() => gotoPage(1)}
                    >
                      2
                    </h6>
                    <h6 className="cursor-pointer text-black font-semibold ">
                      ...
                    </h6>
                    <h6
                      className="cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      onClick={() => gotoPage(pageOptions.length - 1)}
                    >
                      {pageOptions.length}
                    </h6>
                  </div>
                ) : pageIndex === pageOptions.length - 1 ||
                  pageIndex === pageOptions.length - 2 ? (
                  <div className="flex gap-2">
                    <h6
                      className="cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      onClick={() => gotoPage(0)}
                    >
                      1
                    </h6>
                    <h6 className="cursor-pointer text-black font-semibold ">
                      ...
                    </h6>
                    <h6
                      className={
                        pageIndex === pageOptions.length - 2
                          ? "cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                          : "cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      }
                      onClick={() => gotoPage(pageOptions.length - 2)}
                    >
                      {pageOptions.length - 1}
                    </h6>
                    <h6
                      className={
                        pageIndex === pageOptions.length - 1
                          ? "cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                          : "cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      }
                      onClick={() => gotoPage(pageOptions.length - 1)}
                    >
                      {pageOptions.length}
                    </h6>
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <h6
                      className="cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      onClick={() => gotoPage(0)}
                    >
                      1
                    </h6>
                    <h6 className="cursor-pointer text-black font-semibold">
                      ...
                    </h6>
                    <h6
                      className="cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      onClick={() => gotoPage(pageIndex)}
                    >
                      {pageIndex + 1}
                    </h6>
                    <h6 className="cursor-pointer text-black font-semibold">
                      ...
                    </h6>
                    <h6
                      className="cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                      onClick={() => gotoPage(pageOptions.length - 1)}
                    >
                      {pageOptions.length}
                    </h6>
                  </div>
                )
              ) : (
                [...Array(pageOptions.length)].map((item, index) => (
                  <h6
                    key={index}
                    className={
                      pageIndex === index
                        ? "cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                        : "cursor-pointer text-black font-bold rounded-full px-4 py-2 border-2 border-avi-color4"
                    }
                    onClick={() => {
                      gotoPage(index);
                    }}
                  >
                    {index + 1}
                  </h6>
                ))
              )}
              <div
                className="cursor-pointer bg-avi-color4 text-black font-bold rounded-full px-3.5 py-2 border-2 border-avi-color4"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <img src={nodata} alt="" className="h-[500px] m-auto" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default EmployeeTable;
