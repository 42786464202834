import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard";
import Employee from "../pages/employee/employee";
import EmployeeDetail from "../pages/employeeDetail/employeeDetail";
import EmployeeProfile from "../pages/employeeProfile/employeeProfile";
import PatientDetail from "../pages/patientDetail/patientDetail";
import NewEmployee from "../pages/newEmployee/newEmployee";
import EditEmployee from "../pages/editEmployee/editEmployee";
import BookingRequest from "../pages/bookingRequest/bookingRequest";
import BookingDetail from "../pages/bookingDetail/bookingDetail";
import EditRequest from "../pages/editRequest/editRequest";
import NewRequest from "../pages/newRequest/newRequest";
import Patient from "../pages/patient/patient";
import EmployeeAssigned from "../pages/employeeAssigned/employeeAssigned";
import PatientBookingRequest from "../pages/patientBookingRequest/patientBookingRequest";
import CheckBookingDetail from "../pages/checkBookingDetail/checkBookingDetail";
import ViewBookingRequest from "../pages/viewBookingRequest/viewBookingRequest";
import SignIn from "../pages/signin/signIn";
import UserProfile from "../pages/userProfile/userProfile";
import EditUserProfile from "../pages/editUserProfile/editUserProfile";
import UserChangePassword from "../pages/userChangePassword/userChangePassword";
import EmployeeChangePassword from "../pages/employeeChangePassword/employeeChangePassword";
import PatientFeedback from "../pages/patientFeedback/patientFeedback";
import PageNotFound from "../pages/404Page/404Page";
import EmployeeRegistrationRequest from "../pages/employeeRegistrationRequest/employeeRegistrationRequest";

function PublicRoute() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/employee-detail" element={<EmployeeDetail />} />
          <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
          <Route path="/patient-detail" element={<PatientDetail />} />
          <Route path="/edit-employee-profile/:id" element={<EditEmployee />} />
          <Route path="/new-employee-profile" element={<NewEmployee />} />
          <Route path="/booking-request" element={<BookingRequest />} />
          <Route path="/booking-detail/:id" element={<BookingDetail />} />
          <Route path="/edit-request/:id" element={<EditRequest />} />
          <Route path="/new-request" element={<NewRequest />} />
          <Route path="/patient" element={<Patient />} />
          <Route
            path="/employee-change-password/:id"
            element={<EmployeeChangePassword />}
          />
          <Route path="/employee-assigned" element={<EmployeeAssigned />} />
          <Route
            path="/patient-booking-request"
            element={<PatientBookingRequest />}
          />
          <Route
            path="/check-booking-detail"
            element={<CheckBookingDetail />}
          />
          <Route
            path="/view-booking-request/:id"
            element={<ViewBookingRequest />}
          />
          <Route path="/" element={<SignIn />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/edit-user-profile" element={<EditUserProfile />} />
          <Route
            path="/user-change-password/:id"
            element={<UserChangePassword />}
          />
          <Route path="/patient-feedback" element={<PatientFeedback />} />
          <Route path="*" element={<PageNotFound />} />
          <Route
            path="/employee-registration-request"
            element={<EmployeeRegistrationRequest />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PublicRoute;
