import React, { useState, useEffect } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  newBookingListApi,
  newEmployeeBookingRequestApi,
} from "../../apis/bookingApi/bookingApi";
import nodata from "../../assets/images/No data-amico.svg";
import moment from "moment";

function Dashboard() {
  const type = useSelector((state) => state.userType);

  const [newBooking, setNewBooking] = useState([]);

  async function newBookingList() {
    const result = await newBookingListApi();

    if (result !== false) {
      setNewBooking(result);
    }
  }

  async function employeeBookingList() {
    const result = await newEmployeeBookingRequestApi();

    if (result !== false) {
      setNewBooking(result);
    }
  }

  useEffect(() => {
    type === "EMPLOYEE" ? employeeBookingList() : newBookingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="">
          <div className="border border-avi-color2 p-7 rounded-3xl flex justify-between">
            <div className="w-1/2">
              <h3 className="text-2xl text-avi-color3 font-bold">
                Hello{" "}
                <span className="text-avi-color2">
                  {" "}
                  {type !== "EMPLOYEE" ? "Admin" : "Employee"},{" "}
                </span>
              </h3>
              <p className="text-avi-color3 font-normal mt-6">
                From your account dashboard you can view{" "}
                <span className="font-bold">booking requests, </span>{" "}
                {type !== "EMPLOYEE" ? (
                  <>
                    {" "}
                    manage your{" "}
                    <span className="font-bold">employee lists</span> look at{" "}
                    <span className="font-bold">insights, </span>
                  </>
                ) : (
                  <></>
                )}{" "}
                edit your <span className="font-bold"> password </span> and
                <span className="font-bold"> account details.</span>
              </p>
            </div>
            <div className="flex-none self-end">
              <Link to="/booking-request">
                <Button
                  design="primary"
                  content="Check New Requests"
                  padding="py-4 px-10"
                />
              </Link>
            </div>
          </div>

          <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
            <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
              <div className="">
                <h4 className="font-bold text-2xl text-black">
                  {type !== "EMPLOYEE"
                    ? "New Booking Request"
                    : "Upcoming Booking Request"}
                </h4>
                <p className="text-black text-lg font-normal opacity-50">
                  Requests that need your attention
                </p>
              </div>
              <div className="">
                <Link to="/booking-request">
                  <p className="text-lg font-bold text-avi-color1 cursor-pointer">
                    Check All
                  </p>
                </Link>
              </div>
            </div>
            {newBooking.length > 0 ? (
              newBooking.map((item, index) =>
                index < 10 ? (
                  <div className="grid grid-cols-5 gap-5 px-7 py-5" key={index}>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item.fullName}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Name
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item && item.designation && item.designation[0]
                          ? item.designation[0] +
                            item.designation.substring(1).toLowerCase()
                          : ""}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Designation
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {moment(Number(item.requestedDate)).format("LLL")}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Request Date
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item.contact}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Contact Details
                      </p>
                    </div>
                    <div className="justify-self-end">
                      <Link to={"/booking-detail/" + item.id}>
                        <Button
                          design="primary"
                          content="View Request"
                          padding="py-4 px-10"
                        />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              )
            ) : (
              <div className="">
                <img src={nodata} alt="" className="h-[500px] m-auto" />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
