import React from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import { Link } from "react-router-dom";

function EmployeeDetail() {
  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Rajesh P. Rameshwara
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee Name
              </p>
            </div>
            <div className="">
              <Link to="/edit-employee-profile">
                <Button
                  design="ternary"
                  content="Edit Profile"
                  padding="py-4 px-10"
                />
              </Link>
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-4 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Caregiver Specialist
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Designation
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">35</h5>
              <p className="font-normal text-black text-lg opacity-50">Age</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">Female</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Gender
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                Maulana Azad Colony
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Location
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                +91 234 567 8910
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Contact Detail
              </p>
            </div>
            <div className="">
              <h5 className="font-bold text-black text-xl">23 Jun 2022</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Enrollment Request Date
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Location Information
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                43/1 D II Court Lane, Sundarbans Marg, PBO Marg, Behind
                Lakshminagar post office,
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Address
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">New Delhi</h5>
              <p className="font-normal text-black text-lg opacity-50">State</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">110021</h5>
              <p className="font-normal text-black text-lg opacity-50">
                Postal Code
              </p>
            </div>
          </div>
          <div className="py-6 px-7 flex gap-5">
            <Button
              design="primary"
              content="Accept Profile"
              padding="py-4 px-10"
            />
            <Button
              design="secondary"
              content="Reject Profile"
              padding="py-4 px-10"
            />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EmployeeDetail;
