import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import { useSelector } from "react-redux";
import {
  getAdminProfileApi,
  getEmployeeProfileApi,
} from "../../apis/profileApi/profileApi";

function UserProfile() {
  const [data, setData] = useState({});

  async function getProfileApi() {
    const result = await getAdminProfileApi();

    if (result !== false) {
      setData(result);
    }
  }

  const type = useSelector((state) => state.userType);

  async function getEmployeeApi() {
    const result = await getEmployeeProfileApi();

    if (result !== false) {
      setData(result);
    }
  }

  useState(() => {
    if (type === "EMPLOYEE") {
      getEmployeeApi();
    } else {
      getProfileApi();
    }
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">{data.name}</h4>
              <p className="text-black text-lg font-normal opacity-50">
                User Name
              </p>
            </div>
            <div className="flex gap-5">
              <Link to="/edit-user-profile">
                <Button
                  design="ternary"
                  content="Edit Profile"
                  padding="py-4 px-10"
                />
              </Link>
              {type !== "EMPLOYEE" ? (
                <Link to={"/user-change-password/" + data.id}>
                  <Button
                    design="ternary"
                    content="Change Password"
                    padding="py-4 px-10"
                  />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            {type === "EMPLOYEE" ? (
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  {data &&
                  data.profile &&
                  data.profile.designation &&
                  data.profile.designation[0]
                    ? data.profile.designation[0] +
                      data.profile.designation.substring(1).toLowerCase()
                    : ""}
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Designation
                </p>
              </div>
            ) : (
              <></>
            )}
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.age || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">Age</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data &&
                data.profile &&
                data.profile.gender &&
                data.profile.gender[0]
                  ? data.profile.gender[0] +
                    data.profile.gender.substring(1).toLowerCase()
                  : ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Gender
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.locationName || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Location
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.contact || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Contact Detail
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Location Information
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.line1 || ""} ,{" "}
                {data?.profile?.location?.locationName || ""} ,{" "}
                {data?.profile?.location?.landmark || ""} ,{" "}
                {data?.profile?.location?.city || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Address
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.state || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">State</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.zipcode || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Postal Code
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default UserProfile;
