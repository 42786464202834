import {
  checkAdminRefreshApi,
  checkAdminRefreshTokenApi,
  checkEmployeeRefreshApi,
  checkEmployeeRefreshTokenApi,
} from "../../apis/authApi/authApi";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../action/index.action";
import { store } from "./../store/store";

export const getAuthHeader = async () => {
  const type = store.getState().userType;

  async function adminTokenAuth() {
    const token = store.getState().accessToken;

    const result = await checkAdminRefreshApi(token);

    if (result) {
      return token;
    } else {
      async function refreshTokenAuth() {
        const refreshToken = store.getState().refreshToken;

        const result = await checkAdminRefreshTokenApi(refreshToken);

        if (result !== false) {
          store.dispatch({ type: REFRESH_TOKEN, payload: result.refreshToken });
          store.dispatch({ type: ACCESS_TOKEN, payload: result.accessToken });

          return result.accessToken;
        } else {
          window.localStorage.clear();
          window.location.href = "/";
        }
      }
      refreshTokenAuth();
    }
  }

  async function employeeTokenAuth() {
    const token = store.getState().accessToken;

    const result = await checkEmployeeRefreshApi(token);

    if (result) {
      return token;
    } else {
      async function refreshTokenAuth() {
        const refreshToken = store.getState().refreshToken;

        const result = await checkEmployeeRefreshTokenApi(refreshToken);

        if (result !== false) {
          store.dispatch({ type: REFRESH_TOKEN, payload: result.refreshToken });
          store.dispatch({ type: ACCESS_TOKEN, payload: result.accessToken });

          return result.accessToken;
        } else {
          window.localStorage.clear();
          window.location.href = "/";
        }
      }
      refreshTokenAuth();
    }
  }

  return type === "ADMIN" ? adminTokenAuth() : employeeTokenAuth();
  //return "lost";
};
