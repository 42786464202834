import React, { useState, useEffect } from "react";
import Layout from "../../component/layout/layout";
import { ViewIcon } from "../../component/icon/icon";
import { Link } from "react-router-dom";
import Button from "../../component/button/button";
import {
  completedBookingListApi,
  newBookingListApi,
  newEmployeeBookingRequestApi,
  upcomingBookingListApi,
} from "../../apis/bookingApi/bookingApi";
import nodata from "../../assets/images/No data-amico.svg";
import moment from "moment";
import { useSelector } from "react-redux";

function BookingRequest() {
  const [newRequestStatus, setNewRequestStatus] = useState(true);
  const [upcomingRequestStatus, setUpcomingRequestStatus] = useState(false);
  const [closeRequestStatus, setCloseRequestStatus] = useState(false);

  const [newBooking, setNewBooking] = useState([]);

  const [upcomingBooking, setUpcomingBooking] = useState([]);

  const [closeBooking, setCloseBooking] = useState([]);

  const type = useSelector((state) => state.userType);

  async function newBookingList() {
    const result = await newBookingListApi();

    if (result !== false) {
      setNewBooking(result);
    }
  }

  async function upcomingBookingList() {
    const result = await upcomingBookingListApi();

    if (result !== false) {
      setUpcomingBooking(result);
    }
  }

  async function completedBookingList() {
    const result = await completedBookingListApi();

    if (result !== false) {
      setCloseBooking(result);
    }
  }

  async function employeeBookingList() {
    const result = await newEmployeeBookingRequestApi();

    if (result !== false) {
      setUpcomingBooking(result);
    }
  }

  useEffect(() => {
    if (type === "EMPLOYEE") {
      employeeBookingList();
    } else {
      newBookingList();
      upcomingBookingList();
      completedBookingList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        {type !== "EMPLOYEE" ? (
          <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
            <div
              className={
                newRequestStatus
                  ? "flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center"
                  : "flex justify-between py-6 px-7 bg-avi-color6 rounded-3xl items-center"
              }
            >
              <div className="">
                <h4 className="font-bold text-2xl text-black">
                  New Booking Request
                </h4>
                <p className="text-black text-lg font-normal opacity-50">
                  Request that need your attention
                </p>
              </div>
              <div className="flex gap-5 items-center">
                <Link to="/new-request">
                  <Button
                    design="primary"
                    content="New Booking Request"
                    padding="py-4 px-10"
                  />
                </Link>
                {newRequestStatus ? (
                  <div
                    className="text-3xl font-bold cursor-pointer text-avi-color3"
                    onClick={() => {
                      setNewRequestStatus(false);
                    }}
                  >
                    &#9650;
                  </div>
                ) : (
                  <div
                    className="text-3xl font-bold cursor-pointer text-avi-color3"
                    onClick={() => {
                      setNewRequestStatus(true);
                    }}
                  >
                    &#9660;
                  </div>
                )}
              </div>
            </div>
            {newRequestStatus ? (
              newBooking.length > 0 ? (
                newBooking.map((item, index) => (
                  <div
                    className="grid grid-cols-5 gap-5 px-7 py-5 "
                    key={index}
                  >
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item.fullName}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Patient Name
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item && item.designation && item.designation[0]
                          ? item.designation[0] +
                            item.designation.substring(1).toLowerCase()
                          : ""}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Requirement
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {moment(Number(item.requestedDate)).format("LLL")}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Request Date
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item.contact}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Contact Details
                      </p>
                    </div>
                    <div className="">
                      <div className="flex justify-end items-center">
                        <Link to={"/booking-detail/" + item.id}>
                          <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                            <ViewIcon />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="">
                    <img src={nodata} alt="" className="h-[500px] m-auto" />
                  </div>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div
            className={
              upcomingRequestStatus
                ? "flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center"
                : "flex justify-between py-6 px-7 bg-avi-color6 rounded-3xl items-center"
            }
          >
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Upcoming Assignments
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Request that are coming up
              </p>
            </div>
            {upcomingRequestStatus ? (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setUpcomingRequestStatus(false);
                }}
              >
                &#9650;
              </div>
            ) : (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setUpcomingRequestStatus(true);
                }}
              >
                &#9660;
              </div>
            )}
          </div>
          {upcomingRequestStatus ? (
            upcomingBooking.length > 0 ? (
              upcomingBooking.map((item, index) => (
                <div className="grid grid-cols-5 gap-5 px-7 py-5" key={index}>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {item.fullName}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Patient Name
                    </p>
                  </div>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {item && item.designation && item.designation[0]
                        ? item.designation[0] +
                          item.designation.substring(1).toLowerCase()
                        : ""}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Requirement
                    </p>
                  </div>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {moment(Number(item.requestedDate)).format("LLL")}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Request Date
                    </p>
                  </div>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {item?.caretaker?.name || ""}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Assigned Employee
                    </p>
                  </div>
                  <div className="flex justify-end items-center">
                    <Link to={"/booking-detail/" + item.id}>
                      <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                        <ViewIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="">
                  <img src={nodata} alt="" className="h-[500px] m-auto" />
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>

        {type !== "EMPLOYEE" ? (
          <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
            <div
              className={
                closeRequestStatus
                  ? "flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center"
                  : "flex justify-between py-6 px-7 bg-avi-color6 rounded-3xl items-center"
              }
            >
              <div className="">
                <h4 className="font-bold text-2xl text-black">
                  Completed Assignments
                </h4>
                <p className="text-black text-lg font-normal opacity-50">
                  Request that are completed
                </p>
              </div>
              {closeRequestStatus ? (
                <div
                  className="text-3xl font-bold cursor-pointer text-avi-color3"
                  onClick={() => {
                    setCloseRequestStatus(false);
                  }}
                >
                  &#9650;
                </div>
              ) : (
                <div
                  className="text-3xl font-bold cursor-pointer text-avi-color3"
                  onClick={() => {
                    setCloseRequestStatus(true);
                  }}
                >
                  &#9660;
                </div>
              )}
            </div>
            {closeRequestStatus ? (
              closeBooking.length > 0 ? (
                closeBooking.map((item, index) => (
                  <div className="grid grid-cols-5 gap-5 px-7 py-5" key={index}>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item.fullName}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Patient Name
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item && item.designation && item.designation[0]
                          ? item.designation[0] +
                            item.designation.substring(1).toLowerCase()
                          : ""}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Requirement
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {moment(Number(item.requestedDate)).format("LLL")}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Request Date
                      </p>
                    </div>
                    <div className="">
                      <h5 className="font-normal text-black text-xl">
                        {item?.caretaker?.name || ""}
                      </h5>
                      <p className="font-normal text-black text-lg opacity-50">
                        Assigned Employee
                      </p>
                    </div>
                    <div className="flex justify-end items-center">
                      <Link to={"/booking-detail/" + item.id}>
                        <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                          <ViewIcon />
                        </button>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="">
                    <img src={nodata} alt="" className="h-[500px] m-auto" />
                  </div>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Layout>
    </>
  );
}

export default BookingRequest;
