import React from "react";
import { errorToast } from "../../utils/utils";
import Button from "../button/button";

function Upload(props) {
  return (
    <div
      className={
        props.type === "view" ? "relative col-span-1" : "relative col-span-3"
      }
    >
      <p
        className={
          props.type === "view"
            ? "text-lg text-avi-color1 font-normal absolute -top-4 left-7 bg-white px-2"
            : "text-lg text-avi-color1 font-normal absolute -top-4 left-7 bg-white px-2"
        }
      >
        {props.title}
      </p>
      <div
        className={
          props.type === "view"
            ? "rounded-3xl px-6 py-2 focus:outline-none"
            : "border-avi-color2 rounded-3xl px-6 py-5 focus:outline-none border"
        }
      >
        <input
          id={props.id}
          type="file"
          onInvalid={() => {
            errorToast("Please add required documents.");
          }}
          hidden
          onChange={props.onChange}
          required={props?.value?.length > 0 ? false : true}
        />
        {props.type === "view" ? (
          <div className="flex">
            {props?.value?.length > 0 ? (
              <a
                href={
                  process.env.REACT_APP_API_URL +
                  "download?filename=" +
                  props.value +
                  "&type=" +
                  props.id
                }
                className="text-black text-lg font-medium bg-avi-color4 border-2 border-avi-color4 rounded-3xl py-4 px-10 my-4"
                download
              >
                View Document
              </a>
            ) : (
              <>
                <h4 className="text-xl text-black font-bold">
                  {" "}
                  No Document Available{" "}
                </h4>
              </>
            )}
          </div>
        ) : props?.value?.length > 0 ? (
          <div className="flex gap-5">
            <a
              href={
                process.env.REACT_APP_API_URL +
                "download?filename=" +
                props.value +
                "&type=" +
                props.id
              }
              className="text-black text-lg font-medium bg-avi-color4 border-2 border-avi-color4 rounded-3xl py-4 px-10"
              download
            >
              View Document
            </a>
            <Button
              design="primary"
              content="Change Document"
              padding="py-4 px-10"
              type="button"
              onClick={() => {
                props.onDelete();
              }}
            />
          </div>
        ) : (
          <Button
            design="primary"
            content="Upload Document"
            padding="py-4 px-10"
            type="button"
            onClick={() => {
              document.getElementById(props.id).click();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Upload;
