import axios from "axios";
import { getAuthHeader } from "../../redux/services/authHeader";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** get admin profile api function start */

export const getAdminProfileApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/profile", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** get admin profile api function end */

/** edit admin profile api function start */

export const editAdminProfileApi = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .put(process.env.REACT_APP_API_URL + "admin/profile", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** edit admin profile api function end */

/** get employee profile api function start */

export const getEmployeeProfileApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "employee/profile", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** get employee profile api function end */

/** edit employee profile api function start */

export const editEmployeeProfileApi = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .put(process.env.REACT_APP_API_URL + "employee/profile", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** edit employee profile api function end */
