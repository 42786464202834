import React, { useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
import Input from "../../component/input/input";
import { useNavigate } from "react-router-dom";
import {
  editAdminProfileApi,
  editEmployeeProfileApi,
  getAdminProfileApi,
  getEmployeeProfileApi,
} from "../../apis/profileApi/profileApi";
import { useSelector } from "react-redux";
import {
  designationEnumsApi,
  genderEnumsApi,
} from "../../apis/enumsApi/enumsApi";
import { successToast } from "../../utils/utils";

function EditUserProfile() {
  const [genderList, setGenderList] = useState([]);

  const [gender, setGender] = useState({});

  const [destinationList, setDestinationList] = useState([]);

  const [destination, setDestination] = useState({});

  const [data, setData] = useState({
    name: "",
    age: "",
    gender: "",
    designation: "",
    contact: "",
    extraInfo: "",
    locationName: "",
    line1: "",
    landmark: "",
    city: "",
    state: "",
    zipcode: "",
  });

  async function getProfileApi() {
    const result = await getAdminProfileApi();

    if (result !== false) {
      setData({
        name: result.name,
        age: result?.profile?.age || "",
        contact: result?.profile?.contact || "",
        extraInfo: result?.profile?.extraInfo || "",
        locationName: result?.profile?.location?.locationName || "",
        line1: result?.profile?.location?.line1 || "",
        landmark: result?.profile?.location?.landmark || "",
        city: result?.profile?.location?.city || "",
        state: result?.profile?.location?.state || "",
        zipcode: result?.profile?.location?.zipcode || "",
      });

      if (result?.profile?.gender === "MALE") {
        setGender({
          name: "Male",
          value: result?.profile?.gender || "",
        });
      } else if (result?.profile?.gender === "FEMALE") {
        setGender({
          name: "Female",
          value: result?.profile?.gender || "",
        });
      } else if (result?.profile?.gender === "OTHER") {
        setGender({
          name: "Other",
          value: result?.profile?.gender || "",
        });
      }

      if (result?.profile?.designation === "NURSE") {
        setDestination({
          name: "Nurse",
          value: result?.profile?.designation || "",
        });
      } else if (result?.profile?.designation === "CARETAKER") {
        setDestination({
          name: "Care Taker",
          value: result?.profile?.designation || "",
        });
      } else if (result?.profile?.designation === "SPECIALIST") {
        setDestination({
          name: "Specialist",
          value: result?.profile?.designation || "",
        });
      }
    }
  }

  async function getDesignation() {
    const result = await designationEnumsApi();

    const destinationArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      destinationArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setDestinationList(destinationArray);
  }

  async function getGender() {
    const result = await genderEnumsApi();

    const genderArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      genderArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setGenderList(genderArray);
  }
  const navigate = useNavigate();

  async function editProfileApi(e) {
    e.preventDefault();
    if (type === "EMPLOYEE") {
      const result = await editEmployeeProfileApi({
        name: data.name,
        age: data.age,
        gender: gender.value,
        designation: destination.value,
        contact: data.contact,
        extraInfo: data.extraInfo,
        locationName: data.locationName,
        line1: data.line1,
        landmark: data.landmark,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      });

      if (result !== false) {
        setTimeout(() => {
          navigate("/user-profile");
        }, 1000);
        successToast("SuccessFully Edited Profile");
      }
    } else {
      const result = await editAdminProfileApi({
        name: data.name,
        age: data.age,
        gender: gender.value,
        designation: destination.value,
        contact: data.contact,
        extraInfo: data.extraInfo,
        locationName: data.locationName,
        line1: data.line1,
        landmark: data.landmark,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
      });

      if (result !== false) {
        setTimeout(() => {
          navigate("/user-profile");
        }, 1000);
        successToast("SuccessFully Edited Profile");
      }
    }
  }

  const type = useSelector((state) => state.userType);

  async function getEmployeeApi() {
    const result = await getEmployeeProfileApi();

    if (result !== false) {
      setData({
        name: result.name,
        age: result?.profile?.age || "",
        contact: result?.profile?.contact || "",
        extraInfo: result?.profile?.extraInfo || "",
        locationName: result?.profile?.location?.locationName || "",
        line1: result?.profile?.location?.line1 || "",
        landmark: result?.profile?.location?.landmark || "",
        city: result?.profile?.location?.city || "",
        state: result?.profile?.location?.state || "",
        zipcode: result?.profile?.location?.zipcode || "",
      });

      if (result?.profile?.gender === "MALE") {
        setGender({
          name: "Male",
          value: result?.profile?.gender || "",
        });
      } else if (result?.profile?.gender === "FEMALE") {
        setGender({
          name: "Female",
          value: result?.profile?.gender || "",
        });
      } else if (result?.profile?.gender === "OTHER") {
        setGender({
          name: "Other",
          value: result?.profile?.gender || "",
        });
      }

      if (result?.profile?.designation === "NURSE") {
        setDestination({
          name: "Nurse",
          value: result?.profile?.designation || "",
        });
      } else if (result?.profile?.designation === "CARETAKER") {
        setDestination({
          name: "Care Taker",
          value: result?.profile?.designation || "",
        });
      } else if (result?.profile?.designation === "SPECIALIST") {
        setDestination({
          name: "Specialist",
          value: result?.profile?.designation || "",
        });
      }

      if (result && result.profile && result.profile.gender) {
        setGender({
          name:
            result.profile.gender[0] +
            result.profile.gender.substring(1).toLowerCase(),
          value: result.profile.gender || "",
        });
      }

      if (result && result.profile && result.profile.designation) {
        setDestination({
          name:
            result.profile.designation[0] +
            result.profile.designation.substring(1).toLowerCase(),
          value: result.profile.designation || "",
        });
      }
    }
  }

  useState(() => {
    if (type === "EMPLOYEE") {
      getEmployeeApi();
    } else {
      getProfileApi();
    }
    getGender();
    getDesignation();
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">User Details</h4>
              <p className="text-black text-lg font-normal opacity-50">
                User Personal Details
              </p>
            </div>
          </div>
          <form onSubmit={editProfileApi}>
            <div className="py-6 px-7 grid grid-cols-3 gap-7">
              <Input
                title="User Name *"
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData((data) => ({ ...data, name: e.target.value }));
                }}
                required
              />
              {/* <Select
                title="Requirement *"
                value={destination.name}
                options={destinationList}
                optionType="name"
                onClick={(index) => {
                  setDestination(destinationList[index]);
                }}
              /> */}
              <Input
                title="Contact Detail *"
                type="number"
                min={1000000000}
                max={9999999999}
                value={data.contact}
                onChange={(e) => {
                  setData((data) => ({ ...data, contact: e.target.value }));
                }}
                required
              />
              <Input
                title="Age *"
                type="number"
                value={data.age}
                onChange={(e) => {
                  setData((data) => ({ ...data, age: e.target.value }));
                }}
                required
              />
              <Select
                title="Gender *"
                value={gender.name}
                options={genderList}
                optionType="name"
                onClick={(index) => {
                  setGender(genderList[index]);
                }}
              />
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Location Details
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-3 gap-5">
              <Input
                title="Address Line 1 *"
                type="text"
                value={data.line1}
                onChange={(e) => {
                  setData((data) => ({ ...data, line1: e.target.value }));
                }}
                required
              />
              <Input
                title="Locality Name *"
                type="text"
                value={data.locationName}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    locationName: e.target.value,
                  }));
                }}
                required
              />
              <Input
                title="Landmark Name *"
                type="text"
                value={data.landmark}
                onChange={(e) => {
                  setData((data) => ({ ...data, landmark: e.target.value }));
                }}
                required
              />
              <Input
                title="City Name *"
                type="text"
                value={data.city}
                onChange={(e) => {
                  setData((data) => ({ ...data, city: e.target.value }));
                }}
                required
              />
              <Input
                title="State *"
                type="text"
                value={data.state}
                onChange={(e) => {
                  setData((data) => ({ ...data, state: e.target.value }));
                }}
                required
              />
              <Input
                title="Postal code *"
                type="number"
                value={data.zipcode}
                onChange={(e) => {
                  setData((data) => ({ ...data, zipcode: e.target.value }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Save Changes"
                padding="py-4 px-10"
                type="submit"
              />
              <Button
                design="secondary"
                content="Discard Changes"
                padding="py-4 px-10"
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}

export default EditUserProfile;
