import axios from "axios";
import { getAuthHeader } from "../../redux/services/authHeader";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** login api function start */

export const loginApi = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "signin", data)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** login api function end */

/** change password api function start */

export const changePasswordApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "admin/password/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** change password api function end */

/** check admin refresh function start */

export const checkAdminRefreshApi = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "checkAdmin", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return true;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** check admin refresh api function end */

/** check admin refresh token function start */

export const checkAdminRefreshTokenApi = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "rotateAdminToken", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** check admin refresh token api function end */

/** check employee refresh function start */

export const checkEmployeeRefreshApi = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "checkEmployee", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return true;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** check employee refresh api function end */

/** check employee refresh token function start */

export const checkEmployeeRefreshTokenApi = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "rotateEmployeeToken", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** check employee refresh token api function end */
