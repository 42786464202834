import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../component/button/button";
import { Logo } from "../../component/icon/icon";
import Input from "../../component/input/input";

function CheckBookingDetail() {
  const [data, setData] = useState("");

  const navigate = useNavigate();

  async function checkSubmit(e) {
    e.preventDefault();
    navigate("/view-booking-request/" + data);
  }

  return (
    <>
      <div className="px-4 lg:px-20 py-16 lg:mb-0 mb-28">
        <div className="mb-6 flex justify-center gap-6 items-center">
          <div className=" cursor-pointer">
            <Logo />
          </div>
          <h2 className="text-3xl text-avi-color3 font-bold cursor-pointer">
            {" "}
            AVI Caretaker{" "}
          </h2>
        </div>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Check Booking Detail
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee Booking Details
              </p>
            </div>
          </div>
          <form onSubmit={checkSubmit}>
            <div className="py-6 px-7 grid grid-cols-1 gap-7">
              <Input
                title="Enter Booking Detail No."
                type="number"
                onChange={(e) => {
                  setData(e.target.value);
                }}
                required
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Search"
                padding="py-4 px-10"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CheckBookingDetail;
