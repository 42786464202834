import axios from "axios";

/** designation enums api function start */

export const designationEnumsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "enums?designation=true")
    .then((res) => {
      return res.data.data.designation;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

/** designation enums api function end */

/** gender enums api function start */

export const genderEnumsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "enums?gender=true")
    .then((res) => {
      return res.data.data.gender;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

/** gender enums api function end */

/** request status enums api function start */

export const requestStatusEnumsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "enums?requestStatus=true")
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

/** request status enums api function end */

/** employee status enums api function start */

export const employeeStatusEnumsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "enums?employeeStatus=true")
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

/** employee status enums api function end */

/** area enums api function start */

export const areaEnumsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "enums?area=true")
    .then((res) => {
      return res.data.data.area;
    })
    .catch(function (error) {
      return false;
    });

  return result;
};

/** area enums api function end */
