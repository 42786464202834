import React from "react";
import Modal from "react-modal";
import { DeleteIcon } from "../icon/icon";
import "./modal.css";
import Button from "../../component/button/button";

const customStyles = {
  content: {
    position: "absolute",
    top: "calc(50% - 120px)",
    left: "calc(50% - 200px)",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
};

function DeleteModal({ modalState, setModalState, onDelete }) {
  return (
    <>
      <Modal
        isOpen={modalState}
        style={customStyles}
        className="bg-white py-5 px-6 w-[500px] rounded-3xl modal"
        onRequestClose={() => {
          setModalState(false);
        }}
      >
        <div className="flex justify-center">
          <div className="rounded-full bg-avi-color11 py-4 px-4 border-2 border-avi-color9 cursor-pointer">
            <DeleteIcon />
          </div>
        </div>
        <div className="text-center my-6">
          <h5 className="text-black text-xl font-bold">
            Are you sure you want to Delete this Record?
          </h5>
        </div>
        <div className="flex justify-center gap-5">
          <Button
            design="secondary"
            content="Delete"
            padding="py-4 px-10"
            onClick={() => {
              onDelete();
            }}
          />
          <Button
            design="ternary"
            content="Cancel"
            padding="py-4 px-10"
            onClick={() => {
              setModalState(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal;
