import React, { useEffect, useState } from "react";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
//import { useNavigate } from "react-router-dom";
import {
  designationEnumsApi,
  genderEnumsApi,
} from "../../apis/enumsApi/enumsApi";
import Input from "../../component/input/input";
import { noAuthBookingApi } from "../../apis/noAuthApi/noAuthApi";
import BookingDetailModal from "../../component/modal/bookingDetailModal";
import { Logo } from "../../component/icon/icon";
import { useLocation } from "react-router-dom";

function PatientBookingRequest() {
  const [genderList, setGenderList] = useState([]);

  const [gender, setGender] = useState({});

  const [destinationList, setDestinationList] = useState([]);

  const [destination, setDestination] = useState({});

  const [data, setData] = useState({
    name: "",
    age: "",
    weight: "",
    contact: "",
    address: "",
    locality: "",
    landmark: "",
    city: "",
    state: "",
    postal: "",
    information: "",
    requestedDate: "",
    medicalCondition: "",
  });

  const id = useLocation();

  const [modalState, setModalState] = useState(false);

  const [pdfData, setPdfData] = useState([]);

  async function newRequestApi(e) {
    e.preventDefault();

    const result = await noAuthBookingApi({
      designation: destination.value,
      requestedDate: new Date(data.requestedDate).getTime(),
      fullName: data.name,
      gender: gender.value,
      age: data.age,
      weight: data.weight,
      locationName: data.locality,
      line1: data.address,
      city: data.city,
      landmark: data.landmark,
      state: data.state,
      zipcode: data.postal,
      contact: data.contact,
      medicalCondition: data.medicalCondition,
      otherDetails: data.information,
    });

    if (result !== false) {
      setModalState(true);
      setPdfData((data) => [...data, result.request]);
    }
  }

  async function getDesignation() {
    const result = await designationEnumsApi();

    const destinationArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      destinationArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setDestinationList(destinationArray);
  }

  async function getGender() {
    const result = await genderEnumsApi();

    const genderArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      genderArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setGenderList(genderArray);
  }

  useEffect(() => {
    getDesignation();
    getGender();
    if (id.search) {
      setDestination({
        name:
          id.search.split("?params=")[1][0] +
          id.search.split("?params=")[1].substring(1).toLowerCase(),
        value: id.search.split("?params=")[1],
      });
    }
  }, [id.search]);

  return (
    <>
      <div className="px-4 lg:px-20 py-16 lg:mb-0 mb-28">
        <div className="mb-6 flex justify-center gap-6 items-center">
          <div className=" cursor-pointer">
            <Logo />
          </div>
          <h2 className="text-3xl text-avi-color3 font-bold cursor-pointer">
            {" "}
            AVI Caretaker{" "}
          </h2>
        </div>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">Booking Request</h4>
              <p className="text-black text-lg font-normal opacity-50">
                Booking Request Details
              </p>
            </div>
          </div>
          <form onSubmit={newRequestApi} className="divide-y divide-avi-color5">
            <div className="py-6 px-7 grid lg:grid-cols-4 gap-7">
              <Input
                title="Patient Name *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, name: e.target.value }));
                }}
                required
              />
              <Select
                title="Requirement *"
                value={destination.name}
                options={destinationList}
                optionType="name"
                onClick={(index) => {
                  setDestination(destinationList[index]);
                }}
              />
              <Input
                title="Age *"
                type="number"
                onChange={(e) => {
                  setData((data) => ({ ...data, age: e.target.value }));
                }}
                required
              />
              <Input
                title="Weight *"
                type="number"
                onChange={(e) => {
                  setData((data) => ({ ...data, weight: e.target.value }));
                }}
                required
              />
              <Select
                title="Gender *"
                value={gender.name}
                options={genderList}
                optionType="name"
                onClick={(index) => {
                  setGender(genderList[index]);
                }}
              />
              <Input
                title="Contact *"
                type="number"
                min={1000000000}
                max={9999999999}
                onChange={(e) => {
                  setData((data) => ({ ...data, contact: e.target.value }));
                }}
                required
              />
              <Input
                title="Requested Date *"
                type="datetime-local"
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    requestedDate: e.target.value,
                  }));
                }}
                required
              />
              <Input
                title="Medical Condition *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    medicalCondition: e.target.value,
                  }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Location Details
              </h4>
            </div>
            <div className="py-6 px-7 grid lg:grid-cols-3 gap-5">
              <Input
                title="Address Line 1 *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, address: e.target.value }));
                }}
                required
              />
              <Input
                title="Locality Name *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, locality: e.target.value }));
                }}
                required
              />
              <Input
                title="Landmark Name *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, landmark: e.target.value }));
                }}
                required
              />
              <Input
                title="City Name *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, city: e.target.value }));
                }}
                required
              />
              <Input
                title="State *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, state: e.target.value }));
                }}
                required
              />
              <Input
                title="Postal code *"
                type="number"
                onChange={(e) => {
                  setData((data) => ({ ...data, postal: e.target.value }));
                }}
                required
              />
            </div>
            <div className="flex justify-between py-6 px-7 bg-avi-color6 items-center">
              <h4 className="font-bold text-2xl text-black">
                Extra Information
              </h4>
            </div>
            <div className="py-6 px-7 grid lg:grid-cols-1 gap-5">
              <Input
                title="Information *"
                type="text"
                onChange={(e) => {
                  setData((data) => ({ ...data, information: e.target.value }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Save Changes"
                padding="py-4 px-10"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <BookingDetailModal
        modalState={modalState}
        setModalState={setModalState}
        data={pdfData}
      />
    </>
  );
}

export default PatientBookingRequest;
