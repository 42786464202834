import { USER_TYPE } from "../../action/index.action";

export const userType = (state = "", action) => {
  switch (action.type) {
    case USER_TYPE:
      return action.payload;
    default:
      return state;
  }
};
