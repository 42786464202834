import React, { useState, useEffect } from "react";
import { DeleteIcon, ProfileIcon, ViewIcon } from "../../component/icon/icon";
import Layout from "../../component/layout/layout";
import Toggle from "../../component/toggle/toggle";
import Button from "../../component/button/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  changeUserStatusApi,
  deleteEmployeeDetailApi,
  getEmployeeDetailApi,
} from "../../apis/employeeApi/employeeApi";
import nodata from "../../assets/images/No data-amico.svg";
import moment from "moment";
import { successToast } from "../../utils/utils";
import DeleteModal from "../../component/modal/deleteModal";
import Upload from "../../component/upload/upload";

function EmployeeProfile() {
  const [data, setData] = useState({});

  const { id } = useParams();

  const navigate = useNavigate();

  const [assignment, setAssignment] = useState([]);

  const [feedback, setFeedback] = useState([]);

  const [modalState, setModalState] = useState(false);

  async function employeeDetail() {
    const result = await getEmployeeDetailApi(id);

    if (result !== false) {
      if (result.length > 0) {
        setData(result[0]);
        if (result && result[0].caretaker) {
          setAssignment(result[0].caretaker);
          let tempFilter = result[0].caretaker;
          setFeedback(tempFilter.filter((item) => item.feedback));
        }
      } else {
        window.location.href = "/dashboard";
      }
    }
  }

  async function deleteEmployee() {
    const result = await deleteEmployeeDetailApi(id);
    if (result !== false) {
      navigate("/employee");
    }
  }

  async function changeStatus() {
    const result = await changeUserStatusApi({
      empId: id,
      isActive: !data.isActive,
    });

    if (result !== false) {
      successToast("SuccessFully Edited Employee Availability");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  useEffect(() => {
    employeeDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">{data.name}</h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee Name
              </p>
            </div>
            <div className="flex gap-5 items-center">
              <div
                className="rounded-3xl bg-avi-color11 py-4 px-4 border-2 border-avi-color9 cursor-pointer"
                onClick={() => {
                  //  deleteEmployee();
                  setModalState(true);
                }}
              >
                <DeleteIcon />
              </div>
              <div className="text-lg font-medium rounded-3xl bg-white py-4 px-6 border-2 border-avi-color4 text-black flex gap-3 items-center">
                <Toggle
                  active={data.isActive}
                  onClick={() => {
                    changeStatus();
                  }}
                />
                <h5 className=""> {data.isActive ? "Busy" : "Free"} </h5>
              </div>
              <Link to={"/edit-employee-profile/" + data.id}>
                <Button
                  design="ternary"
                  content="Edit Profile"
                  padding="py-4 px-10"
                />
              </Link>
              <Link to={"/employee-change-password/" + data.id}>
                <Button
                  design="ternary"
                  content="Employee Change Password"
                  padding="py-4 px-6"
                />
              </Link>
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-4 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data &&
                data.profile &&
                data.profile.designation &&
                data.profile.designation[0]
                  ? data.profile.designation[0] +
                    data.profile.designation.substring(1).toLowerCase()
                  : ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Designation
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.age || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">Age</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data &&
                data.profile &&
                data.profile.gender &&
                data.profile.gender[0]
                  ? data.profile.gender[0] +
                    data.profile.gender.substring(1).toLowerCase()
                  : ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Gender
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.locationName || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Location
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.contact || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Contact Detail
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl break-words	">
                {data?.email || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Email Id
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.height || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Height
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.weight || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Weight
              </p>
            </div>
            <div className="col-span-3">
              <div className="flex gap-3">
                {data?.profile?.languages_spoken.map((item, index) => (
                  <h5 className="font-normal text-black text-xl" key={index}>
                    {item}
                  </h5>
                ))}
              </div>
              <p className="font-normal text-black text-lg opacity-50">
                Languages Spoken
              </p>
            </div>
            <div className="col-span-3">
              <div className="flex gap-3">
                {data?.profile?.prefered_area.map((item, index) => (
                  <h5 className="font-normal text-black text-xl" key={index}>
                    {item}
                  </h5>
                ))}
              </div>
              <p className="font-normal text-black text-lg opacity-50">
                Preferred Area
              </p>
            </div>
            <div className="col-span-3">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.extraInfo || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Extra Information
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Location Information
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.line1 || ""} ,{" "}
                {data?.profile?.location?.locationName || ""} ,{" "}
                {data?.profile?.location?.landmark || ""} ,{" "}
                {data?.profile?.location?.city || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Address
              </p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.state || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">State</p>
            </div>
            <div className="">
              <h5 className="font-normal text-black text-xl">
                {data?.profile?.location?.zipcode || ""}
              </h5>
              <p className="font-normal text-black text-lg opacity-50">
                Postal Code
              </p>
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Document Uploading
            </h4>
          </div>
          <div className="pt-6 px-7 pb-3 grid grid-cols-3 gap-5">
            <Upload
              title="Aadhar Card "
              type="view"
              value={data?.profile?.aadharCard}
              id="aadharcard"
            />
            <Upload
              title="Certificate "
              type="view"
              value={data?.profile?.certificate}
              id="certificate"
            />
            <Upload
              title="Resume "
              type="view"
              value={data?.profile?.resume}
              id="resume"
            />
          </div>
        </div>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div className="py-6 px-7 bg-avi-color6 rounded-t-3xl ">
            <h4 className="font-bold text-2xl text-black">Assignment List</h4>
          </div>
          <>
            {assignment.length > 0 ? (
              assignment.map((item, index) => (
                <div className="grid grid-cols-4 gap-5 py-6 px-7" key={index}>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {item.fullName}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Patient Name
                    </p>
                  </div>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {moment(Number(item.requestedDate)).format("LLL")}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Date of assignment
                    </p>
                  </div>
                  <div className="">
                    <h5 className="font-normal text-black text-xl">
                      {item.contact}
                    </h5>
                    <p className="font-normal text-black text-lg opacity-50">
                      Contact Details
                    </p>
                  </div>

                  <div className="flex justify-end">
                    <Link to={"/booking-detail/" + item.id}>
                      <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                        <ViewIcon />
                      </button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="">
                <img src={nodata} alt="" className="h-[500px] m-auto" />
              </div>
            )}
          </>
        </div>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div className="py-6 px-7 bg-avi-color6 rounded-t-3xl ">
            <h4 className="font-bold text-2xl text-black">
              Feedback from previously serviced patients
            </h4>
          </div>
          <div className="grid grid-cols-3 gap-5 py-6 px-7">
            {feedback.length > 0 ? (
              feedback.map((item, index) => (
                <div
                  className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl"
                  key={index}
                >
                  <div className="px-5 py-4 bg-avi-color6 rounded-t-3xl flex justify-between items-center">
                    <div className="">
                      <h5 className=" text-xl text-black font-normal">
                        {item.fullName}
                      </h5>
                      <p className="text-lg text-black font-normal opacity-50">
                        Patient Name
                      </p>
                    </div>
                    <div className="border-2 border-avi-color2 rounded-3xl bg-white">
                      <ProfileIcon />
                    </div>
                  </div>
                  <div className="px-5 py-4">
                    <p className="text-lg text-black font-normal">
                      {item.feedback}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-3">
                <img src={nodata} alt="" className="h-[500px] m-auto" />
              </div>
            )}
          </div>
        </div>
      </Layout>
      <DeleteModal
        modalState={modalState}
        setModalState={setModalState}
        onDelete={() => deleteEmployee()}
      />
    </>
  );
}

export default EmployeeProfile;
