import React from "react";
import { Link } from "react-router-dom";
import {
  BookingIcon,
  EmployeeIcon,
  HomeIcon,
  Logo,
  LogoutIcon,
  ProfileIcon,
} from "../icon/icon";
import "./sidebar.css";
import { useSelector } from "react-redux";

function Sidebar() {
  const type = useSelector((state) => state.userType);

  return (
    <>
      <div className="flex flex-col justify-between gap-12 h-full p-9">
        <div className="flex flex-col items-center gap-6">
          <Link to="/dashboard">
            <Logo />
          </Link>
          <Link
            to="/dashboard"
            className="cursor-pointer flex items-center justify-start w-full"
          >
            <div className="p-[12.5px] ">
              <HomeIcon />
            </div>
            <h5 className="text-avi-color3 font-bold text-xl"> Home </h5>
          </Link>
          {type !== "EMPLOYEE" ? (
            <Link
              to="/employee"
              className="cursor-pointer flex items-center justify-start w-full"
            >
              <EmployeeIcon />
              <h5 className="text-avi-color3 font-bold text-xl"> Employee </h5>
            </Link>
          ) : (
            <></>
          )}
          <Link
            to="/booking-request"
            className="cursor-pointer flex items-center justify-start w-full"
          >
            <BookingIcon />
            <h5 className="text-avi-color3 font-bold text-xl"> Booking </h5>
          </Link>
          {/* <Link
            to="/patient"
            className="cursor-pointer flex items-center justify-start w-full"
          >
            <PatientIcon />
            <h5 className="text-avi-color3 font-bold text-xl"> Patient </h5>
          </Link> */}
          {/* <Link to="">
            <div className="cursor-pointer">
              <GraphIcon />
            </div>
          </Link> */}
          <Link
            to="/user-profile"
            className="cursor-pointer flex items-center justify-start w-full"
          >
            <ProfileIcon />
            <h5 className="text-avi-color3 font-bold text-xl"> Profile </h5>
          </Link>
        </div>
        <div
          className="flex items-center cursor-pointer  justify-start w-full gap-5"
          onClick={() => {
            localStorage.clear();
            window.location.href = "/";
          }}
        >
          <LogoutIcon />
          <h5 className="text-avi-color3 font-bold text-xl"> Logout </h5>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
