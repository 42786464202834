import React from "react";

const button = (props) => {
  return (
    <button
      className={
        props.design === "primary"
          ? `text-black text-lg font-medium bg-avi-color4 border-2 border-avi-color4 rounded-3xl ${props.padding}`
          : props.design === "secondary"
          ? `text-lg font-medium rounded-3xl bg-avi-color11 border-2 border-avi-color9 text-avi-color10  
        ${props.padding}`
          : props.design === "ternary"
          ? `text-lg font-medium rounded-3xl bg-white border-2 border-avi-color4 text-black 
        ${props.padding}`
          : `text-lg font-medium rounded-3xl bg-white border-2 border-avi-color8 text-avi-color8
        ${props.padding}`
      }
      {...props}
    >
      {props.content}
    </button>
  );
};

export default button;
