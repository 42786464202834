import React, { useState, useEffect } from "react";
import Layout from "../../component/layout/layout";
import EmployeeTable from "../../component/table/employeeTable";
import {
  getDisableEmployeeApi,
  getEmployeeApi,
} from "../../apis/employeeApi/employeeApi";
import NewEmployeeTable from "../../component/table/newEmployeeTable";

function Employee() {
  const [employeeData, setEmployeeData] = useState([]);
  const [disableEmployeeData, setDisableEmployeeData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getEmployeeFunctionApi() {
      const result = await getEmployeeApi();
      if (result !== false) {
        setEmployeeData(result);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    async function getDisableEmployeeFunctionApi() {
      const result = await getDisableEmployeeApi();
      if (result !== false) {
        setDisableEmployeeData(result);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    getDisableEmployeeFunctionApi();
    getEmployeeFunctionApi();
  }, []);

  // console.log("getEmployees -> ", employeeData);

  const discolumns = [
    {
      Header: "Employee Name",
      accessor: "name",
    },
    {
      Header: "Designation",
      accessor: "profile.designation",
    },
    {
      Header: "Gender",
      accessor: "profile.gender",
    },
    {
      Header: "Age",
      accessor: "profile.age",
    },
    {
      Header: "Location",
      accessor: "profile.location.locationName",
    },
    {
      Header: "Work Status",
      accessor: "isActive",
    },
    {
      Header: "Accept Applicant",
    },
    {
      Header: "Action",
    },
  ];

  const columns = [
    {
      Header: "Employee Name",
      accessor: "name",
    },
    {
      Header: "Designation",
      accessor: "profile.designation",
    },
    {
      Header: "Gender",
      accessor: "profile.gender",
    },
    {
      Header: "Age",
      accessor: "profile.age",
    },
    {
      Header: "Location",
      accessor: "profile.location.locationName",
    },
    {
      Header: "Work Status",
      accessor: "isActive",
    },
    {
      Header: "Action",
    },
  ];

  return (
    <>
      <Layout>
        <div className="">
          <NewEmployeeTable
            columns={discolumns}
            data={disableEmployeeData}
            loading={loading}
            button={true}
          />
          <EmployeeTable
            columns={columns}
            data={employeeData}
            loading={loading}
            button={false}
          />
        </div>
      </Layout>
    </>
  );
}

export default Employee;
