import { ACCESS_TOKEN } from "../../action/index.action";

export const accessToken = (state = "", action) => {
  switch (action.type) {
    case ACCESS_TOKEN:
      return action.payload;
    default:
      return state;
  }
};
