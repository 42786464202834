import React from "react";

function Toggle({ active, onClick }) {
  return (
    <>
      <div
        className="cursor-pointer"
        onClick={() => {
          onClick();
        }}
      >
        <div
          className={
            active
              ? "float-right border-2 border-avi-color8 h-5 w-5 bg-avi-color7 rounded-full transition ease-in-out delay-150"
              : "float-left border-2 border-avi-color10 h-5 w-5 bg-avi-color9 rounded-full transition ease-in-out delay-150"
          }
        ></div>
        <div className="h-[6px] w-10 rounded-full bg-avi-color1 mt-1.5"></div>
      </div>
    </>
  );
}

export default Toggle;
