import React, { useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import moment from "moment";
import Input from "../../component/input/input";
import { employeeApi } from "../../apis/employeeApi/employeeApi";
import { useNavigate } from "react-router-dom";
import { infoToast, successToast } from "../../utils/utils";
import { useEffect } from "react";
import {
  areaEnumsApi,
  designationEnumsApi,
  genderEnumsApi,
} from "../../apis/enumsApi/enumsApi";
import Upload from "../../component/upload/upload";
import {
  uploadDocumentApi,
  uploadDocumentDeleteApi,
} from "../../apis/uploadApi/uploadApi";
import MultiSelect from "../../component/multiSelect/multiSelect";
import Select from "../../component/select/select";

function NewEmployee() {
  const [employeeName, setEmployeeName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [contactDetail, setContactDetail] = useState("");
  const [age, setAge] = useState(null);
  const [extraInfo, setExtraInfo] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [localityName, setLocalityName] = useState("");
  const [landmarkName, setLandmarkName] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [postalcode, setPostalCode] = useState(null);
  const [aadharCard, setAadharCard] = useState("");
  const [certificate, setCertificate] = useState("");
  const [resume, setResume] = useState("");

  const [genderList, setGenderList] = useState([]);

  const [gender, setGender] = useState({});

  const [destinationList, setDestinationList] = useState([]);

  const [destination, setDestination] = useState({});

  const [areaList, setAreaList] = useState([]);

  const [area, setArea] = useState([]);

  const languageList = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Bengali", value: "Bengali" },
    { label: "Urdu", value: "Urdu" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Marathi", value: "Marathi" },
    { label: "Telugu", value: "Telugu" },
    { label: "Tamil", value: "Tamil" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Kannada", value: "Kannada" },
    { label: "Odia", value: "Odia" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Assamese", value: "Assamese" },
    { label: "Santali", value: "Santali" },
    { label: "Meitei", value: "Meitei" },
    { label: "Sanskrit", value: "Sanskrit" },
  ];

  const [language, setLanguage] = useState([]);

  const navigate = useNavigate();

  async function addEmployeeFunctionApi(e) {
    e.preventDefault();

    const areaArray = [];
    for (let i = 0; i < area.length; i++) {
      areaArray.push(area[i].value);
    }

    const languageArray = [];
    for (let i = 0; i < language.length; i++) {
      languageArray.push(language[i].value);
    }

    const result = await employeeApi({
      email: email,
      name: employeeName,
      password: password,
      age: parseInt(age),
      gender: gender.value,
      designation: destination.value,
      contact: contactDetail,
      extraInfo: extraInfo,
      line1: addressLine,
      locationName: localityName,
      landmark: landmarkName,
      city: cityName,
      state: stateName,
      zipcode: postalcode,
      aadharCard: aadharCard,
      certificate: certificate,
      resume: resume,
      height: parseInt(height),
      weight: parseInt(weight),
      languages_spoken: languageArray,
      prefered_area: areaArray,
    });
    if (result !== false) {
      successToast("SuccessFully New Employee Added");
      setTimeout(() => {
        navigate("/employee");
      }, 1000);
    }
  }

  async function getDesignation() {
    const result = await designationEnumsApi();

    const destinationArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      destinationArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setDestinationList(destinationArray);
  }

  async function getGender() {
    const result = await genderEnumsApi();

    const genderArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      genderArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setGenderList(genderArray);
  }

  async function getArea() {
    const result = await areaEnumsApi();

    const areaArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      areaArray.push({
        label: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i].toUpperCase(),
      });
    }

    setAreaList(areaArray);
  }

  async function uploadDocument(e, title) {
    if (e) {
      infoToast("Please Wait Upload Process is in progress.");
      const result = await uploadDocumentApi(e);
      if (result !== false) {
        successToast("Document Upload Successful.");
        if (title === "aadharcard") {
          setAadharCard(result.filename);
        } else if (title === "certificate") {
          setCertificate(result.filename);
        } else if (title === "resume") {
          setResume(result.filename);
        }
      }
    }
  }

  async function deleteDocument(value, title) {
    const result = await uploadDocumentDeleteApi({
      filename: value,
    });
    if (result !== false) {
      document.getElementById(title).value = null;
      document.getElementById(title).click();
      if (title === "aadharcard") {
        setAadharCard("");
      } else if (title === "certificate") {
        setCertificate("");
      } else if (title === "resume") {
        setResume("");
      }
    }
  }

  useEffect(() => {
    getDesignation();
    getGender();
    getArea();
  }, []);

  return (
    <>
      <Layout>
        <form
          className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl"
          onSubmit={addEmployeeFunctionApi}
        >
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">User Details</h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee Personal Details
              </p>
            </div>
            <div className="text-right">
              <h4 className="font-bold text-2xl text-black">
                {moment().format("DD MMM YYYY")} (Today)
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Enrollment Request Date
              </p>
            </div>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-7">
            <Input
              title="Applicant Name *"
              type="text"
              required
              onChange={(e) => {
                setEmployeeName(e.target.value);
              }}
            />
            <Input
              title="Email Address *"
              type="text"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Input
              title="Password *"
              type="password"
              required
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Select
              title="Designation *"
              value={destination.name}
              required
              options={destinationList}
              optionType="name"
              onClick={(index) => {
                setDestination(destinationList[index]);
              }}
            />
            <Input
              title="Contact No. *"
              type="number"
              min={1000000000}
              max={9999999999}
              required
              onChange={(e) => {
                setContactDetail(e.target.value);
              }}
            />
            <Input
              title="Age *"
              type="number"
              required
              onChange={(e) => {
                setAge(e.target.value);
              }}
            />
            <Select
              title="Gender *"
              value={gender.name}
              required
              options={genderList}
              optionType="name"
              onClick={(index) => {
                setGender(genderList[index]);
              }}
            />
            <Input
              title="Height *"
              type="number"
              required
              onChange={(e) => {
                setHeight(e.target.value);
              }}
            />
            <Input
              title="Weight *"
              type="number"
              required
              onChange={(e) => {
                setWeight(e.target.value);
              }}
            />
            <div className="col-span-3">
              <MultiSelect
                title="Languages Spoken *"
                isMulti
                defaultValue={language}
                options={languageList}
                className="border border-avi-color2 rounded-3xl px-6 py-3.5 w-full items-center"
                onChange={(e) => {
                  setLanguage(e);
                }}
              />
            </div>
            <div className="col-span-3">
              <MultiSelect
                title="Preferred Area *"
                isMulti
                defaultValue={area}
                options={areaList}
                className="border border-avi-color2 rounded-3xl px-6 py-3.5 w-full items-center"
                onChange={(e) => {
                  setArea(e);
                }}
              />
            </div>
            <div className="col-span-3">
              <Input
                title="Extra Info"
                type="text"
                onChange={(e) => {
                  setExtraInfo(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">Location Details</h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <Input
              title="Address Line 1 *"
              type="text"
              required
              onChange={(e) => {
                setAddressLine(e.target.value);
              }}
            />
            <Input
              title="Locality Name *"
              type="text"
              required
              onChange={(e) => {
                setLocalityName(e.target.value);
              }}
            />
            <Input
              title="Landmark Name *"
              type="text"
              required
              onChange={(e) => {
                setLandmarkName(e.target.value);
              }}
            />
            <Input
              title="City Name *"
              type="text"
              required
              onChange={(e) => {
                setCityName(e.target.value);
              }}
            />
            <Input
              title="State *"
              type="text"
              required
              onChange={(e) => {
                setStateName(e.target.value);
              }}
            />
            <Input
              title="Postal Code *"
              type="number"
              required
              onChange={(e) => {
                setPostalCode(e.target.value);
              }}
            />
          </div>
          <div className="py-6 px-7 bg-avi-color6">
            <h4 className="font-bold text-2xl text-black">
              Document Uploading
            </h4>
          </div>
          <div className="py-6 px-7 grid grid-cols-3 gap-5">
            <Upload
              title="Aadhar Card * (Only PDF and no More than 2 MB)"
              value={aadharCard}
              id="aadharcard"
              onChange={(e) => {
                uploadDocument(e, "aadharcard");
              }}
              onDelete={() => {
                deleteDocument(aadharCard, "aadharcard");
              }}
            />
            <Upload
              title="Certificate * (Only PDF and no More than 2 MB)"
              value={certificate}
              id="certificate"
              onChange={(e) => {
                uploadDocument(e, "certificate");
              }}
              onDelete={() => {
                deleteDocument(certificate, "certificate");
              }}
            />
            <Upload
              title="Resume * (Only PDF and no More than 2 MB)"
              value={resume}
              id="resume"
              onChange={(e) => {
                uploadDocument(e, "resume");
              }}
              onDelete={() => {
                deleteDocument(resume, "resume");
              }}
            />
          </div>
          <div className="py-6 px-7 flex gap-5">
            <Button
              design="primary"
              content="Save Changes"
              padding="py-4 px-10"
              type="submit"
            />
            <Button
              design="secondary"
              content="Discard Changes"
              padding="py-4 px-10"
              type="button"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </form>
      </Layout>
    </>
  );
}

export default NewEmployee;
