import React from "react";
import PublicRoute from "./routes/publicRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <PublicRoute />
    </>
  );
}

export default App;
