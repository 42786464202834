import React, { useState } from "react";
import { loginApi } from "../../apis/authApi/authApi";
import Button from "../../component/button/button";
import Input from "../../component/input/input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_TYPE,
} from "../../redux/action/index.action";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  async function loginFunctionApi(e) {
    e.preventDefault();
    const result = await loginApi({ email: email, password: password });

    if (result !== false) {
      dispatch({ type: REFRESH_TOKEN, payload: result.refreshToken });
      dispatch({ type: ACCESS_TOKEN, payload: result.accessToken });
      dispatch({ type: USER_TYPE, payload: result.type });
      navigate("/dashboard");
    }
  }

  const token = useSelector((state) => state.accessToken);

  useEffect(() => {
    if (token && token.length > 0) {
      navigate("/dashboard");
    }
  }, [navigate, token]);

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen w-full flex flex-col justify-center">
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl min-w-[700px] m-auto">
          <form
            className="py-6 px-7 bg-white rounded-3xl items-center"
            onSubmit={loginFunctionApi}
          >
            <div className="flex justify-center">
              <h4 className="font-bold text-4xl text-avi-color3">
                Login to AVI Carenet Portal
              </h4>
            </div>
            <div className="grid grid-cols-1 gap-7 mt-12">
              <Input
                title="Enter Email"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <Input
                title="Enter Password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
            </div>
            <div className="mt-12 flex justify-center items-center">
              <Button
                design="primary"
                content="Login"
                padding="py-4 px-10"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignIn;
