import React from "react";
import Modal from "react-modal";
import "./modal.css";
import Button from "../../component/button/button";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const customStyles = {
  content: {
    position: "absolute",
    top: "calc(50% - 120px)",
    left: "calc(50% - 200px)",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
};

function BookingDetailModal({ modalState, setModalState, data }) {
  function exportPDF() {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Payment Data";
    const headers = [
      [
        "Booking Id",
        "Name",
        "Age",
        "Weight",
        "gender",
        "Contact",
        "Address",
        "Locality",
        "Landmark",
        "City",
        "State",
        "Postal",
        "Information",
        "Requested Date",
        "Medical Condition",
        "Designation",
      ],
    ];

    const pdfData = data.map((elt) => [
      elt.id,
      elt.fullName,
      elt.age,
      elt.weight,
      elt.gender,
      elt.contact,
      elt.line1,
      elt.locationName,
      elt.landmark,
      elt.city,
      elt.state,
      elt.zipcode,
      elt.otherDetails,
      moment(Number(elt.requestedDate)).format("LLL"),
      elt.medicalCondition,
      elt.designation,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
      showHead: "everyPage",
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Booking Data.pdf");
  }

  return (
    <>
      <Modal
        isOpen={modalState}
        style={customStyles}
        className="bg-white py-5 px-6 w-[500px] rounded-3xl modal"
        onRequestClose={() => {
          setModalState(false);
        }}
      >
        <div className="text-center">
          <h5 className="text-black text-lg font-bold">
            Thank You for Booking an Appointment With us
          </h5>
        </div>
        <div className="text-center my-6">
          <h3 className=" text-avi-color3 text-3xl font-bold">
            Your booking Id is: {data[0]?.id || ""}
          </h3>
        </div>
        <div className="flex justify-center">
          <Button
            design="secondary"
            content="Download PDF"
            padding="py-4 px-10"
            onClick={() => exportPDF()}
          />
        </div>
      </Modal>
    </>
  );
}

export default BookingDetailModal;
