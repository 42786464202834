import React, { useState, useEffect } from "react";
import Input from "../../component/input/input";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Select from "../../component/select/select";
import { useNavigate, useParams } from "react-router-dom";
import {
  editEmployeeDetailApi,
  getEmployeeDetailApi,
} from "../../apis/employeeApi/employeeApi";
import {
  areaEnumsApi,
  designationEnumsApi,
  genderEnumsApi,
} from "../../apis/enumsApi/enumsApi";
import { infoToast, successToast } from "../../utils/utils";
import {
  uploadDocumentApi,
  uploadDocumentDeleteApi,
} from "../../apis/uploadApi/uploadApi";
import Upload from "../../component/upload/upload";
import MultiSelect from "../../component/multiSelect/multiSelect";

function EditEmployee() {
  const [genderList, setGenderList] = useState([]);

  const [gender, setGender] = useState({});

  const [destinationList, setDestinationList] = useState([]);

  const [destination, setDestination] = useState({});

  const [data, setData] = useState({
    name: "",
    age: "",
    contact: "",
    extraInfo: "",
    locationName: "",
    line1: "",
    landmark: "",
    city: "",
    state: "",
    zipcode: "",
    isActive: "",
    email: "",
  });

  const [aadharCard, setAadharCard] = useState("");

  const [certificate, setCertificate] = useState("");

  const [resume, setResume] = useState("");

  const [areaList, setAreaList] = useState([]);

  const [area, setArea] = useState([]);

  const [height, setHeight] = useState("");

  const [weight, setWeight] = useState("");

  const languageList = [
    { label: "English", value: "English" },
    { label: "Hindi", value: "Hindi" },
    { label: "Bengali", value: "Bengali" },
    { label: "Urdu", value: "Urdu" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Marathi", value: "Marathi" },
    { label: "Telugu", value: "Telugu" },
    { label: "Tamil", value: "Tamil" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Kannada", value: "Kannada" },
    { label: "Odia", value: "Odia" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Assamese", value: "Assamese" },
    { label: "Santali", value: "Santali" },
    { label: "Meitei", value: "Meitei" },
    { label: "Sanskrit", value: "Sanskrit" },
  ];

  const [language, setLanguage] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  async function employeeDetail() {
    const result = await getEmployeeDetailApi(id);

    if (result !== false) {
      setData({
        name: result[0].name,
        age: result[0]?.profile?.age || "",
        contact: result[0]?.profile?.contact || "",
        extraInfo: result[0]?.profile?.extraInfo || "",
        locationName: result[0]?.profile?.location?.locationName || "",
        line1: result[0]?.profile?.location?.line1 || "",
        landmark: result[0]?.profile?.location?.landmark || "",
        city: result[0]?.profile?.location?.city || "",
        state: result[0]?.profile?.location?.state || "",
        zipcode: result[0]?.profile?.location?.zipcode || "",
        isActive: result[0].isActive,
        email: result[0]?.email || "",
        aadharCard: result[0]?.profile?.aadharCard || "",
        certificate: result[0]?.profile?.certificate || "",
        resume: result[0]?.profile?.resume || "",
      });

      setAadharCard(result[0]?.profile?.aadharCard || "");

      setCertificate(result[0]?.profile?.certificate || "");

      setResume(result[0]?.profile?.resume || "");

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.height
      ) {
        setHeight(result[0].profile.height || "");
      }

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.weight
      ) {
        setWeight(result[0].profile.weight || "");
      }

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.languages_spoken
      ) {
        const areaArray = [];

        for (let i = 0; i < result[0].profile.languages_spoken.length; i++) {
          areaArray.push({
            label: result[0].profile.languages_spoken[i],
            value: result[0].profile.languages_spoken[i],
          });
        }

        setLanguage(areaArray);
      }

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.prefered_area
      ) {
        const languageArray = [];

        for (let i = 0; i < result[0].profile.prefered_area.length; i++) {
          languageArray.push({
            label:
              result[0].profile.prefered_area[i][0] +
              result[0].profile.prefered_area[i].substring(1).toLowerCase(),
            value: result[0].profile.prefered_area[i].toUpperCase(),
          });
        }

        setArea(languageArray);
      }

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.gender
      ) {
        setGender({
          name:
            result[0].profile.gender[0] +
            result[0].profile.gender.substring(1).toLowerCase(),
          value: result[0].profile.gender || "",
        });
      }

      if (
        result &&
        result[0] &&
        result[0].profile &&
        result[0].profile.designation
      ) {
        setDestination({
          name:
            result[0].profile.designation[0] +
            result[0].profile.designation.substring(1).toLowerCase(),
          value: result[0].profile.designation || "",
        });
      }
    }
  }

  async function editEmployeeApi(e) {
    e.preventDefault();

    const areaArray = [];
    for (let i = 0; i < area.length; i++) {
      areaArray.push(area[i].value);
    }

    const languageArray = [];
    for (let i = 0; i < language.length; i++) {
      languageArray.push(language[i].value);
    }

    const result = await editEmployeeDetailApi(id, {
      name: data.name,
      age: parseInt(data.age),
      gender: gender.value,
      designation: destination.value,
      contact: data.contact,
      extraInfo: data.extraInfo,
      locationName: data.locationName,
      line1: data.line1,
      landmark: data.landmark,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      isActive: data.isActive,
      email: data.email,
      aadharCard: aadharCard,
      certificate: certificate,
      resume: resume,
      height: parseInt(height),
      weight: parseInt(weight),
      languages_spoken: languageArray,
      prefered_area: areaArray,
    });

    if (result !== false) {
      successToast("SuccessFully Edited Employee Profile");
      setTimeout(() => {
        navigate("/employee-profile/" + id);
      }, 1000);
    }
  }

  async function getDesignation() {
    const result = await designationEnumsApi();

    const destinationArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      destinationArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setDestinationList(destinationArray);
  }

  async function getGender() {
    const result = await genderEnumsApi();

    const genderArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      genderArray.push({
        name: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i],
      });
    }

    setGenderList(genderArray);
  }

  async function getArea() {
    const result = await areaEnumsApi();

    const areaArray = [];
    for (let i = 0; i < Object.keys(result).length; i++) {
      areaArray.push({
        label: result[i][0] + result[i].substring(1).toLowerCase(),
        value: result[i].toUpperCase(),
      });
    }

    setAreaList(areaArray);
  }

  async function uploadDocument(e, title) {
    if (e) {
      infoToast("Please Wait Upload Process is in progress.");
      const result = await uploadDocumentApi(e);
      if (result !== false) {
        successToast("Document Upload Successful.");
        if (title === "aadharcard") {
          setAadharCard(result.filename);
        } else if (title === "certificate") {
          setCertificate(result.filename);
        } else if (title === "resume") {
          setResume(result.filename);
        }
      }
    }
  }

  async function deleteDocument(value, title) {
    const result = await uploadDocumentDeleteApi({
      filename: value,
    });
    if (result !== false) {
      document.getElementById(title).value = null;
      document.getElementById(title).click();
      if (title === "aadharcard") {
        setAadharCard("");
      } else if (title === "certificate") {
        setCertificate("");
      } else if (title === "resume") {
        setResume("");
      }
    }
  }

  useEffect(() => {
    employeeDetail();
    getDesignation();
    getGender();
    getArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">User Details</h4>
              <p className="text-black text-lg font-normal opacity-50">
                Employee Personal Details
              </p>
            </div>
          </div>
          <form onSubmit={editEmployeeApi}>
            <div className="py-6 px-7 grid grid-cols-3 gap-7">
              <Input
                title="Employee Name *"
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData((data) => ({ ...data, name: e.target.value }));
                }}
                required
              />
              <Select
                title="Requirement *"
                value={destination.name}
                options={destinationList}
                optionType="name"
                onClick={(index) => {
                  setDestination(destinationList[index]);
                }}
              />
              <Input
                title="Contact Detail *"
                type="number"
                min={1000000000}
                max={9999999999}
                value={data.contact}
                onChange={(e) => {
                  setData((data) => ({ ...data, contact: e.target.value }));
                }}
                required
              />
              <Input
                title="Age *"
                type="number"
                value={data.age}
                onChange={(e) => {
                  setData((data) => ({ ...data, age: e.target.value }));
                }}
                required
              />
              <Select
                title="Gender *"
                value={gender.name}
                options={genderList}
                optionType="name"
                onClick={(index) => {
                  setGender(genderList[index]);
                }}
              />
              <div>
                <Input
                  title="Email Id"
                  type="email"
                  value={data.email}
                  disabled
                />
              </div>
              <Input
                title="Height *"
                type="number"
                required
                value={height}
                onChange={(e) => {
                  setHeight(e.target.value);
                }}
              />
              <Input
                title="Weight *"
                type="number"
                required
                value={weight}
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
              />
              <div className="col-span-3">
                <MultiSelect
                  title="Languages Spoken *"
                  isMulti
                  value={language}
                  options={languageList}
                  className="border border-avi-color2 rounded-3xl px-6 py-3.5 w-full items-center"
                  onChange={(e) => {
                    setLanguage(e);
                  }}
                />
              </div>
              <div className="col-span-3">
                <MultiSelect
                  title="Preferred Area *"
                  isMulti
                  value={area}
                  options={areaList}
                  className="border border-avi-color2 rounded-3xl px-6 py-3.5 w-full items-center"
                  onChange={(e) => {
                    setArea(e);
                  }}
                />
              </div>
              <div className="col-span-3">
                <Input
                  title="Extra Info"
                  type="text"
                  value={data.extraInfo}
                  onChange={(e) => {
                    setData((data) => ({ ...data, extraInfo: e.target.value }));
                  }}
                />
              </div>
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Location Details
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-3 gap-5">
              <Input
                title="Address Line 1 *"
                type="text"
                value={data.line1}
                onChange={(e) => {
                  setData((data) => ({ ...data, line1: e.target.value }));
                }}
                required
              />
              <Input
                title="Locality Name *"
                type="text"
                value={data.locationName}
                onChange={(e) => {
                  setData((data) => ({
                    ...data,
                    locationName: e.target.value,
                  }));
                }}
                required
              />
              <Input
                title="Landmark Name *"
                type="text"
                value={data.landmark}
                onChange={(e) => {
                  setData((data) => ({ ...data, landmark: e.target.value }));
                }}
                required
              />
              <Input
                title="City Name *"
                type="text"
                value={data.city}
                onChange={(e) => {
                  setData((data) => ({ ...data, city: e.target.value }));
                }}
                required
              />
              <Input
                title="State *"
                type="text"
                value={data.state}
                onChange={(e) => {
                  setData((data) => ({ ...data, state: e.target.value }));
                }}
                required
              />
              <Input
                title="Postal code *"
                type="number"
                value={data.zipcode}
                onChange={(e) => {
                  setData((data) => ({ ...data, zipcode: e.target.value }));
                }}
                required
              />
            </div>
            <div className="py-6 px-7 bg-avi-color6">
              <h4 className="font-bold text-2xl text-black">
                Document Uploading
              </h4>
            </div>
            <div className="py-6 px-7 grid grid-cols-3 gap-5">
              <Upload
                title="Aadhar Card * (Only PDF and no More than 2 MB)"
                value={aadharCard}
                id="aadharcard"
                onChange={(e) => {
                  uploadDocument(e, "aadharcard");
                }}
                onDelete={() => {
                  deleteDocument(aadharCard, "aadharcard");
                }}
              />
              <Upload
                title="Certificate * (Only PDF and no More than 2 MB)"
                value={certificate}
                id="certificate"
                onChange={(e) => {
                  uploadDocument(e, "certificate");
                }}
                onDelete={() => {
                  deleteDocument(certificate, "certificate");
                }}
              />
              <Upload
                title="Resume * (Only PDF and no More than 2 MB)"
                value={resume}
                id="resume"
                onChange={(e) => {
                  uploadDocument(e, "resume");
                }}
                onDelete={() => {
                  deleteDocument(resume, "resume");
                }}
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Save Changes"
                padding="py-4 px-10"
                type="submit"
              />
              <Button
                design="secondary"
                content="Discard Changes"
                padding="py-4 px-10"
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}

export default EditEmployee;
