import React from "react";
import Sidebar from "../sidebar/sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../header/header";
import "./layout.css";

function Layout(props) {
  return (
    <>
      <ToastContainer />
      <React.Fragment>
        <div className="overflow-x-hidden overflow-y-hidden flex divide-x divide-avi-color1 max-h-screen min-h-screen">
          <div className="overflow-y-auto sidebar-container min-h-screen flex-none">
            <Sidebar />
          </div>
          <div className="overflow-y-auto min-h-screen w-full divide-y divide-avi-color1">
            <div className="header-container">
              <Header />
            </div>
            <div className="body-container">{props.children}</div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default Layout;
