import React, { useState } from "react";
import Layout from "../../component/layout/layout";
import Button from "../../component/button/button";
import Input from "../../component/input/input";
import { useNavigate, useParams } from "react-router-dom";
import { changePasswordApi } from "../../apis/authApi/authApi";

function UserChangePassword() {
  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  async function changePassword(e) {
    e.preventDefault();
    if (password === confirmPassword) {
      const result = await changePasswordApi(id, { password: password });
      if (result !== false) {
        navigate("/user-profile");
      }
    }
  }

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl">
          <div className="flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center">
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Change Password for User
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                User change password
              </p>
            </div>
          </div>
          <form onSubmit={changePassword}>
            <div className="py-6 px-7 grid grid-cols-1 gap-7">
              <Input
                title="Change Password *"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
              />
              <Input
                title="Confirm Change Password *"
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                required
              />
            </div>
            <div className="py-6 px-7 flex gap-5">
              <Button
                design="primary"
                content="Save Changes"
                padding="py-4 px-10"
                type="submit"
              />
              <Button
                design="secondary"
                content="Discard Changes"
                padding="py-4 px-10"
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
}

export default UserChangePassword;
