import React from "react";
import Select from "react-select";
import "./multiSelect.css";

function MultiSelect(props) {
  return (
    <>
      <div className="relative">
        <p className="text-lg text-avi-color1 font-normal absolute z-10 -top-4 left-7 bg-white px-2">
          {props.title}
        </p>
        <Select {...props} />
      </div>
    </>
  );
}

export default MultiSelect;
