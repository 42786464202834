import axios from "axios";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** patient feedback api function start */

export const patientFeedbackApi = async (data) => {
  const { id, feedback } = data;

  const result = await axios
    .put(process.env.REACT_APP_API_URL + `request/${id}`, {
      feedback: feedback,
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};
/** patient feedback api function end */
