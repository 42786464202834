import axios from "axios";
import { getAuthHeader } from "../../redux/services/authHeader";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** new booking request api function start */

export const newBookingRequestApi = async (data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "request", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** new booking request api function end */

/** edit booking request api function start */

export const editBookingRequestApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .put(process.env.REACT_APP_API_URL + "admin/request/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** edit booking request api function end */

/** delete booking request api function start */

export const deleteBookingRequestApi = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .delete(process.env.REACT_APP_API_URL + "admin/request/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** delete booking request api function end */

/** new booking list api function start */

export const newBookingListApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/requests?status=PENDING", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** new booking list api function end */

/** upcoming booking list api function start */

export const upcomingBookingListApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/requests?status=ASSIGNED", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** upcoming booking list api function end */

/** completed booking list api function start */

export const completedBookingListApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/requests?status=COMPLETED", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** completed booking list api function end */

/** booking detail api function start */

export const bookingDetailApi = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "admin/requests?id=" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** booking detail api function end */

/** employee assign api function start */

export const employeeAssignApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .patch(process.env.REACT_APP_API_URL + "admin/request/" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** employee assign api function end */

/** re employee assign api function start */

export const reEmployeeAssignApi = async (id, data) => {
  let token = await getAuthHeader();

  const result = await axios
    .post(
      process.env.REACT_APP_API_URL + "admin/reAssignEmployee/" + id,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** re employee assign api function end */

/** re employee assign api function start */

export const availableEmployeeApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(
      process.env.REACT_APP_API_URL +
        "admin/employees?type=EMPLOYEE&isActive=false",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** re employee assign api function end */

/** new Employee booking request api function start */

export const newEmployeeBookingRequestApi = async () => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "employee/requests?status=ASSIGNED", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** new Employee booking request api function end */

/** booking detail api function start */

export const employeeBookingDetailApi = async (id) => {
  let token = await getAuthHeader();

  const result = await axios
    .get(process.env.REACT_APP_API_URL + "employee/requests?id=" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** booking detail api function end */
