import axios from "axios";
import { errorToast, ServerError, SystemError } from "../../utils/utils";

/** upload document api function start */

export const uploadDocumentApi = async (e) => {
  var fd = new FormData();
  fd.append("file", e.target.files[0]);

  const result = await axios
    .post(process.env.REACT_APP_API_URL + "upload", fd)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** upload document api function end */

/** upload document delete api function start */

export const uploadDocumentDeleteApi = async (data) => {
  const result = await axios
    .delete(process.env.REACT_APP_API_URL + "delete", data)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        errorToast(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        errorToast(ServerError);
      } else {
        // Something happened in setting up the request that triggered an Error
        errorToast(SystemError);
      }
      return false;
    });

  return result;
};

/** upload document delete api function end */
