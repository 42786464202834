import React, { useState } from "react";
import Layout from "../../component/layout/layout";
import { ViewIcon } from "../../component/icon/icon";
import { Link } from "react-router-dom";

function EmployeeAssigned() {
  const [upcomingRequestStatus, setUpcomingRequestStatus] = useState(true);
  const [closeRequestStatus, setCloseRequestStatus] = useState(true);

  return (
    <>
      <Layout>
        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div
            className={
              upcomingRequestStatus
                ? "flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center"
                : "flex justify-between py-6 px-7 bg-avi-color6 rounded-3xl items-center"
            }
          >
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Upcoming Assignments
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Request that need your attention
              </p>
            </div>
            {upcomingRequestStatus ? (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setUpcomingRequestStatus(false);
                }}
              >
                &#9650;
              </div>
            ) : (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setUpcomingRequestStatus(true);
                }}
              >
                &#9660;
              </div>
            )}
          </div>
          {upcomingRequestStatus ? (
            <div className="grid grid-cols-5 gap-5 px-7 py-5">
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Sohan Lal Kapoor
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Patient Name
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Caregiver (Specialist)
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Requirement
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  30 August 2022
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Request Date
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Mohanlal Pradhan Godh
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Assigned Employee
                </p>
              </div>
              <div className="flex justify-end items-center">
                <Link to="/booking-detail">
                  <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                    <ViewIcon />
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="border border-avi-color2 flex flex-col divide-y divide-avi-color5 rounded-3xl mt-8">
          <div
            className={
              closeRequestStatus
                ? "flex justify-between py-6 px-7 bg-avi-color6 rounded-t-3xl items-center"
                : "flex justify-between py-6 px-7 bg-avi-color6 rounded-3xl items-center"
            }
          >
            <div className="">
              <h4 className="font-bold text-2xl text-black">
                Completed Assignments
              </h4>
              <p className="text-black text-lg font-normal opacity-50">
                Request that need your attention
              </p>
            </div>
            {closeRequestStatus ? (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setCloseRequestStatus(false);
                }}
              >
                &#9650;
              </div>
            ) : (
              <div
                className="text-3xl font-bold cursor-pointer text-avi-color3"
                onClick={() => {
                  setCloseRequestStatus(true);
                }}
              >
                &#9660;
              </div>
            )}
          </div>
          {closeRequestStatus ? (
            <div className="grid grid-cols-5 gap-5 px-7 py-5 ">
              <div className="">
                <h5 className="font-normal text-black text-xl">Roopa</h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Patient Name
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  Physiotherapist
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Requirement
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">
                  30 August 2022
                </h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Request Date
                </p>
              </div>
              <div className="">
                <h5 className="font-normal text-black text-xl">Mohanlal</h5>
                <p className="font-normal text-black text-lg opacity-50">
                  Assigned Employee
                </p>
              </div>
              <div className="flex justify-end items-center">
                <Link to="/booking-detail">
                  <button className="font-medium rounded-3xl bg-avi-color4 py-4 px-4 border-2 border-avi-color4">
                    <ViewIcon />
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Layout>
    </>
  );
}

export default EmployeeAssigned;
